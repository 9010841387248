<template>
  <el-breadcrumb separator-class="el-icon-arrow-right">
    <el-breadcrumb-item :to="{ path: '/BannerManagement' }"
      >广告管理</el-breadcrumb-item
    >
    <el-breadcrumb-item></el-breadcrumb-item>
  </el-breadcrumb>
  <div>
    <!-- 1.筛选条件 -->
    <div class="select-box">
      <!--            <div>-->
      <!--                <span>用户名称:</span>-->
      <!--                <el-input v-model="option.userName" clearable placeholder="请输入用户名称"></el-input>-->
      <!--            </div>-->
      <div>
        <span>会员编号:</span>
        <el-input
          v-model="option.memberId"
          clearable
          placeholder="请输入会员编号"
        ></el-input>
      </div>
      <div>
        <el-button @click="getTabList" icon="el-icon-search" type="primary"
          >查 询</el-button
        >
      </div>
    </div>
    <!--2. table]  -->
    <el-table border stripe :data="data.table" style="width: 100%">
      <el-table-column
        type="index"
        :index="indexMethod"
        width="50"
        style="text-align: center"
      >
      </el-table-column>
      <el-table-column prop="memberId" label="会员编号" width="200">
      </el-table-column>
      <!-- <el-table-column  prop="wxUserName" label="微信用户名称"> </el-table-column>-->
      <el-table-column
        v-for="item in data.applicationTasks"
        :key="item.id"
        :prop="item.id"
        :label="item.name"
      ></el-table-column>
      <el-table-column
        prop="creationTime"
        label="报名时间"
        :formatter="formatCreationTime"
        width="160"
      ></el-table-column>
    </el-table>
    <!-- 3.分页 -->
    <el-pagination
      class="fenye"
      @current-change="handleCurrentChange"
      :current-page="option.page"
      :page-size="10"
      prev-text="&nbsp;&nbsp;&nbsp;上一页&nbsp;&nbsp;&nbsp;"
      next-text="&nbsp;&nbsp;&nbsp;下一页&nbsp;&nbsp;&nbsp;"
      background
      layout="total, prev, pager, next, jumper"
      :total="data.total"
    >
    </el-pagination>
  </div>
</template>

<!-- 本页的js -->
<script>
import { ref, reactive, onMounted } from "vue";
import { FullTimeFormat } from "../utils/common";
import { useRoute, useRouter } from "vue-router";
import { getApplicationProjectList } from "../http/bannermanagement";
export default {
  methods: {
    formatCreationTime(row) {
      return FullTimeFormat(row.creationTime);
    },
  },
  name: "ApplicationProject",
  setup() {
    const route = useRoute();
    const router = useRouter();
    //params
    let bannerManagementId = route.query.bannerManagementId;
    onMounted(async () => {
      // 在onMounted生命周期钩子中调用GetAllListAsync方法
      if (bannerManagementId == null || bannerManagementId.trim().length == 0) {
        router.push({ name: "BannerManagement" });
      }
    });
    //声明数据table表格的内容//
    let data = reactive({
      table: [],
      pages: 0,
      total: 0,
      ListData: [],
      applicationTasks: [
        { id: "1", name: "姓名" },
        { id: "2", name: "电话" },
        { id: "3", name: "城市" },
        { id: "4", name: "地址" },
      ],
    });
    //声明查询条件
    let option = reactive({
      /*userName: "",*/
      memberId: "",
      phone: "",
      bannerManagementId: bannerManagementId,
      page: 1,
      pageSize: 10,
    });
    let indexMethod = (index) => {
      return index + (option.page - 1) * option.pageSize + 1;
    };
    let getApplicationProjectListFun = async (obj) => {
      let arr = await getApplicationProjectList(obj);
      data.total = arr.totalCount;
      let arrTable = [];
      arr.items.forEach(function (item, index) {
        arrTable[index] = {
          memberId: item.memberId,
          /*wxUserName:item.wxUserName,*/
          creationTime: item.creationTime,
        };
        for (let key in item.projectList) {
          arrTable[index][key] = item.projectList[key];
        }
      });
      data.table = arrTable;
    };
    //初始化时，获取初始化数据
    getApplicationProjectListFun({
      Page: 1,
      PageSize: 10,
      bannerManagementId: bannerManagementId,
    });

    //点击页码获取数据
    let handleCurrentChange = (index) => {
      option.page = index;
      getApplicationProjectListFun(option);
    };
    //查询方法,此处需要根据条件到后台进行筛选
    let getTabList = async () => {
      await getApplicationProjectListFun(option);
    };
    return {
      data,
      handleCurrentChange,
      getTabList,
      option,
      indexMethod,
    };
  },
};
</script>
<!-- 本页的css -->
<style lang="scss" scoped>
.select-box {
  display: flex;
  align-items: center;
  width: 120%;
  margin-bottom: 25px;
  > div {
    width: 15%;
    display: flex;
    align-items: center;
    span {
      width: 110px;
    }
    margin-right: 25px;
  }
}
.fenye {
  margin-top: 20px;
}
</style>
