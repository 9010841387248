<template>
  <div class="Information">
    <div class="Information_info">
      <el-row class="mt16">
        <el-col :span="6">
          <span class="name">生日：</span>
          <span class="content">{{ info.birthday || "暂无" }}</span>
        </el-col>
        <el-col :span="6">
          <span class="name">婚姻状况：</span>
          <span class="content">{{ info.maritalStatus || "暂无" }}</span>
        </el-col>
        <el-col :span="6">
          <span class="name">性别：</span>
          <span class="content">{{
            info.sex === "w" ? "女" : info.sex == "m" ? "男" : info.sex
          }}</span>
        </el-col>
        <el-col :span="6">
          <span class="name">个人忌讳：</span>
          <span class="content">{{ info.individualTaboo || "暂无" }}</span>
        </el-col>
      </el-row>
      <el-row class="mt16">
        <el-col :span="6">
          <span class="name">负责人：</span>
          <span class="content">{{ info.principal || "暂无" }}</span>
        </el-col>
        <el-col :span="6">
          <span class="name">星座：</span>
          <span class="content">{{ info.constellation || "暂无" }}</span>
        </el-col>
        <el-col :span="6">
          <span class="name">婚次：</span>
          <span class="content">{{ info.weddingTime || "暂无" }}</span>
        </el-col>
        <el-col :span="6">
          <span class="name">生活习惯：</span>
          <span class="content">{{ info.lifeStyle || "暂无" }}</span>
        </el-col>
      </el-row>
      <el-row class="mt16">
        <el-col :span="6">
          <span class="name">家庭住址：</span>
          <span class="content">{{ info.familyAddress || "暂无" }}</span>
        </el-col>
        <el-col :span="6">
          <span class="name">进店渠道：</span>
          <span class="content">{{ info.memberChannelName || "暂无" }}</span>
        </el-col>
        <el-col :span="6">
          <span class="name">生肖：</span>
          <span class="content">{{ info.signAnimal || "暂无" }}</span>
        </el-col>
        <el-col :span="6">
          <span class="name">工作单位：</span>
          <span class="content">{{ info.workUnit || "暂无" }}</span>
        </el-col>
      </el-row>
      <el-row class="mt16">
        <el-col :span="6">
          <span class="name">年收入：</span>
          <span class="content">{{ info.annualIncome || "暂无" }}</span>
        </el-col>
        <el-col :span="6">
          <span class="name">推荐人：</span>
          <span class="content">{{ info.referrer || "暂无" }}</span>
        </el-col>
        <el-col :span="6">
          <span class="name">血型：</span>
          <span class="content">{{ info.bloodType || "暂无" }}</span>
        </el-col>
        <el-col :span="6">
          <span class="name">职业：</span>
          <span class="content">{{ info.profession || "暂无" }}</span>
        </el-col>
      </el-row>
      <el-row class="mt16">
        <el-col :span="6">
          <span class="name">职位：</span>
          <span class="content">{{ info.position || "暂无" }}</span>
        </el-col>
        <el-col :span="6">
          <span class="name">QQ：</span>
          <span class="content">{{ info.qq || "暂无" }}</span>
        </el-col>
        <el-col :span="6">
          <span class="name">微信：</span>
          <span class="content">{{ info.wechat || "暂无" }}</span>
        </el-col>
        <el-col :span="6">
          <span class="name">拓客进店时间：</span>
          <span class="content">{{ info.widdenIntoShopDate || "暂无" }}</span>
        </el-col>
      </el-row>
      <el-row class="mt16">
        <el-col :span="6">
          <span class="name">拓客进店项目：</span>
          <span class="content" style="padding-right: 10px">{{
            info.widdenIntoShopProject || "暂无"
          }}</span>
        </el-col>
        <el-col :span="6">
          <span class="name">转化会员时间：</span>
          <span class="content">{{
            info.convertingMembershipTime || "暂无"
          }}</span>
        </el-col>
        <el-col :span="6">
          <span class="name">转化项目：</span>
          <span class="content">{{
            info.transformationProject || "暂无"
          }}</span>
        </el-col>
        <el-col :span="6">
          <span class="name">喜欢颜色：</span>
          <span class="content">{{ info.favoriteColors || "暂无" }}</span>
        </el-col>
      </el-row>
      <el-row class="mt16">
        <el-col :span="6">
          <span class="name">宗教信仰：</span>
          <span class="content">{{ info.faith || "暂无" }}</span>
        </el-col>
        <el-col :span="6">
          <span class="name">经济独立：</span>
          <span class="content">{{ info.economicIndependence || "暂无" }}</span>
        </el-col>
        <el-col :span="6">
          <span class="name">出行方式：</span>
          <span class="content">{{ info.tripMode || "暂无" }}</span>
        </el-col>
        <el-col :span="6">
          <span class="name">备注：</span>
          <span class="content">{{ info.remark || "暂无" }}</span>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    info: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.Information {
  .title_gk {
    font-size: 16px;
    margin-top: 20px;
    font-weight: 600;
    color: #333;
    margin-bottom: 5px;
  }
  .mt16 {
    margin-top: 16px;
  }
  .mt30 {
    margin-top: 30px;
  }
  .title_member {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 20px;
  }
  .Information_info {
    padding: 35px 18px;
    box-sizing: border-box;
    background-color: #fff;
    .title {
      font-size: 14px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.85);
    }
    .name {
      font-size: 16px;
      color: rgba(0, 0, 0, 0.85);
    }
    .content {
      font-size: 16px;
      color: rgba(0, 0, 0, 0.65);
    }
    .top-main-item {
      display: flex;
      align-items: center;
      .item {
        width: 164px;
        height: 80px;
        background: #f5f5f5;
        padding: 16px;
        box-sizing: border-box;
        margin-right: 15px;
        > span {
          font-size: 11px;
          font-weight: 400;
          color: #000;
        }
        .number {
          margin-top: 8px;
        }
      }
    }
  }
}
</style>
