<template>
  <el-breadcrumb separator-class="el-icon-arrow-right">
    <el-breadcrumb-item :to="{ path: '/BannerManagement' }"
      >广告管理</el-breadcrumb-item
    >
    <el-breadcrumb-item></el-breadcrumb-item>
  </el-breadcrumb>
  <div>
    <!-- 1.筛选条件 -->
    <div class="select-box">
      <!--<div>
                <span>用户名称:</span>
                <el-input v-model="option.userName" clearable placeholder="请输入用户名称"></el-input>
            </div>-->
      <div>
        <span>会员编号:</span>
        <el-input
          v-model="option.memberId"
          clearable
          placeholder="请输入会员编号"
        ></el-input>
      </div>
      <div>
        <span>优惠券:</span>
        <el-select
          v-model="option.couponsId"
          clearable
          filterable
          placeholder="请选择优惠券"
        >
          <el-option
            v-for="item in data.couponListData"
            :key="item.coupon_id"
            :label="item.coupon_name"
            :value="item.coupon_id"
          >
          </el-option>
        </el-select>
      </div>
      <div>
        <el-button @click="getTabList" icon="el-icon-search" type="primary"
          >查 询</el-button
        >
      </div>
    </div>
    <!--2. table]  -->
    <el-table border stripe :data="data.table" style="width: 100%">
      <el-table-column
        type="index"
        :index="indexMethod"
        width="50"
        style="text-align: center"
      >
      </el-table-column>
      <el-table-column prop="memberID" label="会员编号"> </el-table-column>
      <!--<el-table-column  prop="userName" label="微信用户名称"> </el-table-column>-->
      <el-table-column prop="couponsName" label="获取优惠券"> </el-table-column>
      <el-table-column
        prop="creationTime"
        label="推荐时间"
        :formatter="formatCreationTime"
        width="160"
      ></el-table-column>
    </el-table>
    <!-- 3.分页 -->
    <el-pagination
      class="fenye"
      @current-change="handleCurrentChange"
      :current-page="option.page"
      :page-size="10"
      prev-text="&nbsp;&nbsp;&nbsp;上一页&nbsp;&nbsp;&nbsp;"
      next-text="&nbsp;&nbsp;&nbsp;下一页&nbsp;&nbsp;&nbsp;"
      background
      layout="total, prev, pager, next, jumper"
      :total="data.total"
    >
    </el-pagination>
  </div>
</template>

<!-- 本页的js -->
<script>
import { ref, reactive, onMounted } from "vue";
import { FullTimeFormat } from "../utils/common";
import { useRoute, useRouter } from "vue-router";
import { getCouponsRule, getUserCouponsList } from "../http/bannermanagement";
export default {
  methods: {
    formatCreationTime(row) {
      return FullTimeFormat(row.creationTime);
    },
  },
  name: "UserCoupons",
  setup() {
    const route = useRoute();
    const router = useRouter();
    //params
    let bannerManagementId = route.query.bannerManagementId;
    onMounted(async () => {
      // 在onMounted生命周期钩子中调用GetAllListAsync方法
      if (bannerManagementId == null || bannerManagementId.trim().length == 0) {
        router.push({ name: "BannerManagement" });
      }
    });
    //声明数据table表格的内容//
    let data = reactive({
      table: [],
      pages: 0,
      total: 0,
      ListData: [],
      couponListData: [],
    });
    //声明查询条件
    let option = reactive({
      /*userName: "",*/
      memberId: "",
      couponsId: null,
      bannerManagementId: bannerManagementId,
      page: 1,
      pageSize: 10,
    });
    let indexMethod = (index) => {
      return index + (option.page - 1) * option.pageSize + 1;
    };
    let getUserCouponsListFun = async (obj) => {
      if (option.couponsId == "") {
        option.couponsId = null;
        obj.couponsId = null;
      }
      let arr = await getUserCouponsList(obj);
      data.total = arr.totalCount;
      data.table = arr.items;
    };
    //初始化时，获取初始化数据
    getUserCouponsListFun({
      Page: 1,
      PageSize: 10,
      bannerManagementId: bannerManagementId,
    });

    //点击页码获取数据
    let handleCurrentChange = (index) => {
      option.page = index;
      getUserCouponsListFun(option);
    };
    //查询方法,此处需要根据条件到后台进行筛选
    let getTabList = async () => {
      await getUserCouponsListFun(option);
    };
    //获取项目列表方法
    let getCouponsRuleListFun = async (obj) => {
      let arr = await getCouponsRule(obj);
      data.couponListData = arr.data.coupon_rule_list;
    };
    //初始化
    getCouponsRuleListFun({ get_type: 10 });
    return {
      data,
      handleCurrentChange,
      getTabList,
      option,
      indexMethod,
    };
  },
};
</script>
<!-- 本页的css -->
<style lang="scss" scoped>
.select-box {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 25px;
  > div {
    width: 30%;
    display: flex;
    align-items: center;
    span {
      width: 110px;
    }
    margin-right: 25px;
  }
}
.fenye {
  margin-top: 20px;
}
</style>
