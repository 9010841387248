<template>
  <el-dialog title="客诉处理" v-model="dialogVisible" width="700px">
    <el-form
      ref="formRef"
      :model="formData"
      size="small"
      :rules="formRules"
      v-loading="formLoading"
      label-width="88px"
    >
      <el-form-item label="会员姓名" prop="clientName">
        <el-input v-model.trim="formData.clientName" disabled />
      </el-form-item>
      <el-form-item label="跟进状态" prop="followUpStatus">
        <el-select
          v-model="formData.followUpStatus"
          placeholder="请选择跟进状态"
          clearable
        >
          <el-option :key="0" :value="'0'" label="未完成" />
          <el-option :key="1" :value="'1'" label="处理中" />
          <el-option :key="2" :value="'2'" label="已完成" />
        </el-select>
      </el-form-item>
      <el-form-item label="跟进人" prop="followUper">
        <el-input
          v-model.trim="formData.followUper"
          placeholder="请输入跟进人"
          clearable
          maxlength="20"
        />
      </el-form-item>
      <el-form-item label="跟进内容" prop="followUpContent">
        <el-input
          v-model="formData.followUpContent"
          placeholder="请输入跟进内容"
          clearable
          type="textarea"
          :rows="2"
          :autosize="{ minRows: 2, maxRows: 8 }"
          maxlength="200"
          show-word-limit
          @blur="
            (v) => (formData.followUpContent = formData.followUpContent?.trim())
          "
        />
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="onClickSubmit" type="primary" :disabled="formLoading"
        >提交</el-button
      >
      <el-button @click="dialogVisible = false">取 消</el-button>
    </template>
  </el-dialog>
</template>
<script setup lang="js">
import { nextTick, reactive, ref } from "vue";
import { ElMessage } from 'element-plus'
import { FullTimeFormat } from "../../utils/common.js";
import {
  getComplaintDetail,
  updateFollowUp,
} from "../../http/complaint";

/** 客户投诉管理表单 **/
defineOptions({ name: "CustomerComplaintForm" });

const dialogVisible = ref(false); // 弹窗的是否展示
const formLoading = ref(false); // 表单的加载中：1）修改时的数据加载；2）提交的按钮禁用
const formData = ref({});
const formRules = reactive({
  followUpStatus: [{ required: true, message: "跟进状态不能为空", trigger: "blur" }],
  followUper: [{ required: true, message: "跟进人不能为空", trigger: "blur" }],
  followUpContent: [{ required: true, message: "跟进内容不能为空", trigger: "blur" }],
});
const formRef = ref(); // 表单 Ref

/** 打开弹窗 */
const open = async (row) => {
  dialogVisible.value = true;
  resetForm();
  formLoading.value = true;
  try {
    let data = await getComplaintDetail({id: row.id})
    let form = {complaintId: data.id, followUpStatus: data.followUpStatus, followUper: data.followUper, followUpContent: data.followUpContent, clientName: data.clientName}
    formData.value = form
  } finally {
    formLoading.value = false
  }
};
defineExpose({ open }); // 提供 open 方法，用于打开弹窗

/** 提交表单 */
const emit = defineEmits(["success"]); // 定义 success 事件，用于操作成功后的回调

const onClickSubmit = async () => {
    // 校验表单
  if (!formRef.value) return;
  const valid = await formRef.value.validate();
  if (!valid) return;

  submitForm()
}

const submitForm = async () => {
  // 校验表单
  if (!formRef.value) return;
  const valid = await formRef.value.validate();
  if (!valid) return;
  // 提交请求
  formLoading.value = true;
  try {
    let {code, message, data} = await updateFollowUp(formData.value)
    if (code != 200) {
        ElMessage.error({
            message: message || '提交失败',
            type: 'error'
        })
        return
    }
    ElMessage.success({
      message: '修改成功',
      duration: 2000,
    })
    dialogVisible.value = false
    // 发送操作成功的事件
    emit("success");
  } catch (e) {
    ElMessage.error({
        message: e || '提交失败',
        offset:130,
        duration:3000
    });
  } finally {
    formLoading.value = false;
  }
};

/** 重置表单 */
const resetForm = () => {
  formData.value = {
    complaintId: undefined,
    followUpStatus: undefined,
    followUper: undefined,
    followUpContent: undefined,
  };
  formRef.value?.resetFields();
};


const formatTime = (date) => {
      return FullTimeFormat(date);
    };
</script>

<style lang="scss" scoped></style>
