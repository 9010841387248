<template>
  <el-dialog title="咨询对话" v-model="dialogVisible" width="700px">
    <el-scrollbar ref="scrollbarRef" height="400px" always class="scrollbar">
      <div
        ref="innerRef"
        class="inner-box"
        style="display: flex; flex-direction: column"
      >
        <div
          v-for="(item, index) in sessionObj.consultingList"
          :key="index"
          class="list-box"
        >
          <div v-if="item.spokespersonType == 1" class="left-block">
            <div class="left-column">
              <el-image
                :src="sessionObj.avatarUrl"
                fit="scale-down"
                class="avatar"
              ></el-image>
            </div>
            <div class="right-column">
              <div class="text-group">
                <text lines="1" class="nick-name">{{
                  sessionObj.nickName
                }}</text>
                <text lines="1" decode="true" class="date">{{
                  formatTime(item.creationTime)
                }}</text>
              </div>
              <div class="content-wrapper">
                <text decode="true" class="sys-content">{{
                  item.conversationContent
                }}</text>
              </div>
            </div>
          </div>
          <div v-else-if="item.spokespersonType == 2" class="right-block">
            <div class="left-column">
              <div class="text-group">
                <text lines="1" class="nick-name">SKIN79客服</text>
                <text lines="1" decode="true" class="date">{{
                  formatTime(item.creationTime)
                }}</text>
              </div>
              <div class="content-wrapper">
                <text lines="1" class="content">{{
                  item.conversationContent
                }}</text>
              </div>
            </div>
            <div class="right-column">
              <div class="avatar-box">
                <text lines="1" class="avatar">skin79</text>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-scrollbar>
    <div class="form-box">
      <el-form
        ref="formRef"
        :model="formData"
        size="small"
        :rules="formRules"
        v-loading="formLoading"
      >
        <el-form-item label="内容" prop="content">
          <el-input
            v-model="formData.content"
            placeholder="请输入内容"
            clearable
            type="textarea"
            :rows="2"
            :autosize="{ minRows: 2, maxRows: 8 }"
            maxlength="500"
            show-word-limit
            @blur="(v) => (formData.content = formData.content?.trim())"
          />
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <el-button @click="onClickSubmit" type="primary" :disabled="formLoading"
        >提交</el-button
      >
      <el-button @click="dialogVisible = false">取 消</el-button>
    </template>
  </el-dialog>
</template>
<script setup lang="js">
import * as ConsultationApi from "../../http/consultation.js";
import { nextTick, reactive, ref } from "vue";
import { ElMessage, ElMessageBox } from 'element-plus'
import { FullTimeFormat } from "../../utils/common";

/** 客户咨询对话表单 **/
defineOptions({ name: "CustomerConsultationForm" });

const dialogVisible = ref(false); // 弹窗的是否展示
const formLoading = ref(false); // 表单的加载中：1）修改时的数据加载；2）提交的按钮禁用
const formData = ref({
  customerConsultingId: undefined,
  content: undefined
});
const formRules = reactive({
  customerConsultingId: [{ required: true, message: "id不能为空", trigger: "blur" }],
  content: [{ required: true, message: "内容不能为空", trigger: "blur" }],
});
const formRef = ref(); // 表单 Ref
const scrollbarRef = ref()
const innerRef = ref()

const sessionObj = ref({}) // 会话数据对象啊

/** 打开弹窗 */
const open = async (row) => {
  dialogVisible.value = true;
  resetForm();
  resetList()
  formLoading.value = true;
  try {
    let res = await ConsultationApi.getConsultationDetail({ id : row.id });
    sessionObj.value = res.data
    formData.value.customerConsultingId = res.data.customerConsultingId
    scrollToBottom()
  } finally {
    formLoading.value = false;
  }
};
defineExpose({ open }); // 提供 open 方法，用于打开弹窗

/** 提交表单 */
const emit = defineEmits(["success"]); // 定义 success 事件，用于操作成功后的回调

const onClickSubmit = async () => {
    // 校验表单
  if (!formRef.value) return;
  const valid = await formRef.value.validate();
  if (!valid) return;

    ElMessageBox.confirm(
    '即将推送消息到客户的微信公众号，是否发送？',
    '提示',
    {
      confirmButtonText: '发送',
      cancelButtonText: '取消',
      type: 'warning',
    }
  )
    .then(() => {
      submitForm()
    })
    .catch(() => {

    })
}

const submitForm = async () => {
  // 校验表单
  if (!formRef.value) return;
  const valid = await formRef.value.validate();
  if (!valid) return;
  // 提交请求
  formLoading.value = true;
  try {
    let {code, message, data} = await ConsultationApi.postSendConsultationMsg(formData.value)
    if (code != 200) {
        ElMessage.error({
            message: message || '提交失败',
            type: 'error'
        })
        return
    }
    // 插入到list
    sessionObj.value.consultingList.push(data)
    scrollToBottom()
    clearInput()
    // 发送操作成功的事件
    emit("success");
  } finally {
    formLoading.value = false;
  }
};

/** 重置列表 */
const resetList = () => {
  sessionObj.value = []
}

/** 重置表单 */
const resetForm = () => {
  formData.value = {
  customerConsultingId: undefined,
  content: undefined
};
  formRef.value?.resetFields();
};

/** 清空输入 */
const clearInput = () => {
    formData.value.content = undefined;
}

/** 滚动到底部 */
const scrollToBottom = () => {
    nextTick(() => {
        scrollbarRef.value.setScrollTop(innerRef.value.clientHeight)
    })
}

const formatTime = (date) => {
      return FullTimeFormat(date);
    };
</script>

<style lang="scss" scoped>
.scrollbar {
  padding-right: 0px;
  .inner-box {
    background-color: rgba(246, 248, 250, 1);
    padding-bottom: 20px;
    min-height: 350px;
  }
}
.left-block {
  flex-direction: row;
  display: flex;
  justify-content: flex-start;
  margin: 15px calc(10px + 35px + 10px) 0 12px;

  .left-column {
    .avatar {
      margin-right: 10px;
      width: 35px;
      height: 35px;
      border-radius: 50%;
    }
  }

  .right-column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .text-group {
      flex-direction: row;
      display: flex;
      justify-content: flex-start;
      margin-bottom: 7px;

      .nick-name {
        overflow-wrap: break-word;
        color: rgba(170, 168, 169, 1);
        font-size: 12px;
        font-family: PingFangSC-Regular;
        font-weight: normal;
        text-align: center;
        white-space: nowrap;
        line-height: 12px;
      }

      .date {
        overflow-wrap: break-word;
        color: rgba(157, 159, 160, 1);
        font-size: 12px;
        font-family: PingFangSC-Regular;
        font-weight: normal;
        text-align: left;
        white-space: nowrap;
        line-height: 12px;
        margin-left: 10px;
      }
    }

    .content-wrapper {
      background-color: rgba(255, 255, 255, 1);
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      padding: 15px;

      .sys-content {
        overflow-wrap: break-word;
        color: rgba(75, 74, 78, 1);
        font-size: 13px;
        font-family: PingFangSC-Regular;
        font-weight: normal;
        text-align: left;
        line-height: 14px;
      }
    }
  }
}

.right-block {
  flex-direction: row;
  display: flex;
  justify-content: flex-end;
  margin: 15px 12px 0 calc(10px + 35px + 10px);

  .left-column {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .text-group {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 12px;

      .nick-name {
        overflow-wrap: break-word;
        color: rgba(170, 168, 169, 1);
        font-size: 12px;
        font-family: PingFangSC-Regular;
        font-weight: normal;
        text-align: center;
        white-space: nowrap;
        line-height: 12px;
      }

      .date {
        overflow-wrap: break-word;
        color: rgba(157, 159, 160, 1);
        font-size: 12px;
        font-family: PingFangSC-Regular;
        font-weight: normal;
        text-align: center;
        white-space: nowrap;
        line-height: 12px;
        margin-left: 10px;
      }
    }

    .content-wrapper {
      background-color: rgba(76, 185, 179, 1);
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      padding: 15px;

      // width: fit-content;
      .content {
        overflow-wrap: break-word;
        color: white;
        font-size: 13px;
        letter-spacing: 1px;
        font-family: PingFangSC-Regular;
        font-weight: normal;
        text-align: justify;
        line-height: 14px;
      }
    }
  }

  .right-column {
    .avatar-box {
      background-color: rgba(76, 185, 179, 1);
      border-radius: 50%;
      width: 35px;
      height: 35px;
      margin-bottom: 52px;
      display: flex;
      flex-direction: column;
      margin-left: 10px;

      .avatar {
        overflow-wrap: break-word;
        color: rgba(255, 255, 255, 1);
        font-size: 7px;
        text-align: center;
        white-space: nowrap;
        line-height: 35px;
        // width: 100%;
        width: 35px;
        height: 35px;
      }
    }
  }
}
.form-box {
  margin: 10px 0;
}
</style>
