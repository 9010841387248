import $http from "./index";
import { PLATFROM_CONFIG } from "../../public/config";
let base = PLATFROM_CONFIG.baseURL;

/** 客户咨询相关接口 */

// 获取咨询列表
export const getConsultationList = (data) => {
  return $http.get(`/app/info/customerconsulting/consultingsByBack`, {
    params: data,
  });
};

// 获取咨询详情
export const getConsultationDetail = (data) => {
  return $http.get(`/app/info/customerconsulting/queryConsultingDetailByBack`, {
    params: data,
  });
};

// 咨询-发送消息
export const postSendConsultationMsg = (data) => {
  return $http.post(`/app/info/customerconsulting/sendConsultingByBack`, data);
};

// 咨询-设置咨询类型
export const postSetConsultationType = (data) => {
  return $http.post(`/app/info/customerconsulting/setConsultingType`, data);
};

// 导出咨询列表
export const exportConsultation = (data) => {
  return $http.get(`/app/info/customerconsulting/consultingsByBackExport`, {
    params: data,
  });
};

// 咨询类型列表
export const getConsultationTypeList = (data) => {
  return $http.get(`/app/info/customerconsulting/getConsultingTypeList`, {
    params: data,
  });
};

// 删除咨询类型
export const deleteConsultationType = (data) => {
  return $http.post(`/app/info/customerconsulting/deleteConsultingType`, data);
};

// 更新咨询类型
export const updateConsultationType = (data) => {
  return $http.post(`/app/info/customerconsulting/updateConsultingType`, data);
};

// 新增咨询类型
export const createConsultationType = (data) => {
  return $http.post(`/app/info/customerconsulting/createConsultingType`, data);
};
