import $http from "./index";

// 获取转发列表
export const transpondList = (data) => {
  return $http.get(`/app/business/transferconfig/back/getTransferConfigList`, { params: data });
};
// 转发列表详情
export const transpondDetail = (data) => {
  return $http.get(`app/business/transferconfig/back/getTransferConfigById`, { params: data });
};
// 更新
export const updateTranferConfig = (data) => {
  return $http.post(`/app/business/transferconfig/back/updateTranferConfig`, data);
};