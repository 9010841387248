<template>
  <el-dialog title="设置咨询类型" v-model="dialogVisible" width="700px">
    <el-form
      ref="formRef"
      :model="formData"
      size="small"
      :rules="formRules"
      label-width="110px"
      v-loading="formLoading"
    >
      <el-form-item label="ID" prop="id">
        <el-input v-model.trim="formData.id" clearable disabled />
      </el-form-item>
      <el-form-item label="咨询类型" prop="dictValue">
        <el-select
          v-model="formData.dictValue"
          placeholder="请选择咨询类型"
          clearable
        >
          <el-option
            v-for="t in typeOptions"
            :key="t.id"
            :value="t.value"
            :label="t.key"
          />
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="submitForm" type="primary" :disabled="formLoading"
        >确定</el-button
      >
      <el-button @click="dialogVisible = false">取 消</el-button>
    </template>
  </el-dialog>
</template>
<script setup lang="js">
import {
  getConsultationTypeList,postSetConsultationType
} from "../../http/consultation.js";
import { nextTick, onMounted, reactive, ref } from "vue";
import { ElMessage, ElMessageBox } from 'element-plus'

/** 设置咨询类型表单 **/
defineOptions({ name: "ConsultationSetTypeForm" });

const dialogVisible = ref(false); // 弹窗的是否展示
const formLoading = ref(false); // 表单的加载中：1）修改时的数据加载；2）提交的按钮禁用
const formData = ref({
});
const formRules = reactive({
  dictValue: [{ required: true, message: "类型不能为空", trigger: "blur" }],
  id: [{ required: true, message: "会话id不能为空", trigger: "blur" }],
});
const formRef = ref(); // 表单 Ref

const typeOptions = ref([])

// 获取咨询类型选项
const fetchConsultationTypeList = async () => {
  try {
    let res = await getConsultationTypeList();
    typeOptions.value = res ?? [];
  } catch (e) {
    console.log("===获取咨询类型选项：", e);
  }
};

/** 打开弹窗 */
const open = async (row) => {
  dialogVisible.value = true;
  resetForm();
  formLoading.value = true;
  if (row) {
    formData.value = {
      id: row.id,
      dictValue: row.consultingType,
    }
  }
  formLoading.value = false;
};
defineExpose({ open }); // 提供 open 方法，用于打开弹窗

/** 提交表单 */
const emit = defineEmits(["success"]); // 定义 success 事件，用于操作成功后的回调

const submitForm = async () => {
  // 校验表单
  if (!formRef.value) return;
  const valid = await formRef.value.validate();
  if (!valid) return;
  // 提交请求
  formLoading.value = true;
  try {
    await postSetConsultationType(formData.value)
    ElMessage.success('修改成功')
    dialogVisible.value = false
    // 发送操作成功的事件
    emit("success");
  } finally {
    formLoading.value = false;
  }
};

/** 重置表单 */
const resetForm = () => {
  formData.value = {
  id: undefined,
  dictValue: undefined,
};
  formRef.value?.resetFields();
};
onMounted(() => {
  fetchConsultationTypeList()
})
</script>

<style lang="scss" scoped></style>
