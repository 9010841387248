<template>
  <div class="wenzi">服务评价配置</div>
  <div class="table-container">
    <!--   &lt;!&ndash;2. table表格的内容 data.table[option.page]  &ndash;&gt;
        <el-table border stripe :data="data.table2" style="width:100%"  >
            <el-table-column prop="evalContent" label="注意事项内容维护" >
                <template #default="scope">
                    <div style="display: flex; align-items: center">
                        <el-input v-model="scope.row.evalContent" :rows="2"
                                  type="textarea"
                                  :disabled="data.evacinput"></el-input>


                    </div>
                </template>
            </el-table-column>
            <el-table-column fixed="right" width="200">
                &lt;!&ndash;操作固定列 &ndash;&gt;
                <template #default="scope">
                    <el-button
                            @click="UpdateClick1(scope.row)"
                            size="small"
                            icon="el-icon-edit"
                            type="primary"
                    >修改
                    </el-button>
                </template>
            </el-table-column>
        </el-table>-->

    <el-table border stripe :data="data.table1" style="width: 100%">
      <el-table-column prop="id" label="ID" v-if="false"></el-table-column
      ><!--隐藏列-->

      <el-table-column
        type="index"
        :index="indexMethod"
        width="50"
        style="text-align: center"
      >
      </el-table-column>

      <el-table-column prop="evalContent" label="问题名称"></el-table-column>
      <el-table-column prop="sort" label="排序" width="150"></el-table-column>
      <el-table-column fixed="right" label="操作" width="200">
        <!--操作固定列 -->
        <template #default="scope">
          <el-button
            @click="UpdateClick2(scope.row)"
            size="small"
            icon="el-icon-edit"
            type="primary"
            >修改
          </el-button>

          <el-button
            @click="DeleteClick(scope.row)"
            size="small"
            icon="el-icon-edit"
            type="danger"
            >删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- &lt;!&ndash; 4.新增或更新操作 嵌套的表单  打开嵌套表单的 Dialog&ndash;&gt;
        <el-dialog width="32%" title="注意事项" :show-close=true v-model="ruleForm.dialogFormVisible">
            <el-form :model="ruleForm" :rules="rules" ref="ruleFormRef" label-width="100px" class="demo-ruleForm">
                <el-form-item label="名称" prop="evalContent" required>
                    <el-input v-model="ruleForm.evalContent" :rows="2"
                              type="textarea"
                             ></el-input>
                </el-form-item>
            </el-form>
            <template #footer>
        <span class="dialog-footer">
          <el-button @click="resetForm('ruleForm')">取 消</el-button>
          <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>

        </span>
            </template>
        </el-dialog>-->
    <el-dialog
      width="32%"
      title="服务评价配置"
      :show-close="true"
      v-model="ruleForm.dialogFormVisible2"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleFormRef"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="名称" prop="evalContent" required>
          <el-input v-model="ruleForm.evalContent"></el-input>
        </el-form-item>
        <el-form-item label="排序" prop="Sort" required>
          <el-input v-model="ruleForm.Sort"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="resetForm('ruleForm')">取 消</el-button>
          <el-button type="primary" @click="submitForm('ruleForm')"
            >确 定</el-button
          >
        </span>
      </template>
    </el-dialog>

    <el-dialog
      title="温馨提示"
      v-model="data.deleteDialogVisible"
      width="30%"
      center
    >
      <div style="text-align: center; margin-bottom: 50px">确定要删除吗？</div>
      <div slot="footer" class="dialog-footer" style="text-align: center">
        <el-button @click="CancelDelete">取 消</el-button>
        <el-button type="primary" @click="ConfirmDelete">确 定</el-button>
      </div>
    </el-dialog>
    <div class="select-box">
      <el-button icon="el-icon-document-add" @click="AddClick()" type="primary">
        新增
      </el-button>
    </div>
  </div>
</template>

<!-- 本页的js -->
<script>
import { ref, reactive, onMounted } from "vue";
import {
  getEvaluationConfigurationList,
  deleteEvaluationConfiguration,
  getEConfigurationByID,
  updateEvaluationConfiguration,
  addEvaluationConfiguration,
} from "../http/evaluationconfiguration";
import { ElMessage } from "element-plus";

export default {
  name: "EvaluationConfiguration",
  setup() {
    const ruleFormRef = ref(null); //表单不能用this.$,取一个重名的
    onMounted(() => {
      // console.log(ruleFormRef.value);
    });
    //声明数据table表格的内容///////////////////////////////////
    let data = reactive({
      table2: [],
      table1: [],
      pages: 0,
      total: 0,
      evacinput: true,
      deleteDialogVisible: false,
    });
    //声明查询条件
    let option = reactive({
      page: 1,
      evalType: "",
      pageSize: 10,
    });
    let indexMethod = (index) => {
      return index + (option.page - 1) * option.pageSize + 1;
    };

    let getEvaluationConfigurationListFun = async () => {
      let arr = await getEvaluationConfigurationList();

      data.total = arr.totalCount;
      data.table1 = arr.filter((el) => el.evalType === 1);
      /* data.table2 = arr.filter(el => el.evalType === 2);*/
    };

    //初始化时，获取初始化数据
    getEvaluationConfigurationListFun();

    //查询方法,此处需要根据条件到后台进行筛选
    let getTabList = async () => {
      //let token = Cookies.get('name');
      //console.log(token);
      await getEvaluationConfigurationListFun(option);
    };

    //修改的表单参数//////////////////////////////////////////////////
    let ruleForm = reactive({
      dialogFormVisible: false,
      dialogFormVisible2: false,
      id: "",
      evalContent: "",
      Sort: "",
      evalType: "",
      addOrUpdate: 0, //0新增，1更新
    });
    //修改的表单验证
    let rules = reactive({
      evalContent: [{ required: true, message: "请输入问题名称" }],
      Sort: [{ required: true, message: "请输入排序" }],
    });

    //点击修改按钮,弹框，默认把原来的数据显示出来
    let UpdateClick1 = async (obj) => {
      console.log("UpdateClick", UpdateClick1), (ruleForm.addOrUpdate = 1);

      ruleForm.id = obj.id;
      //赋值默认值弹框获取ID号，方便下一步进行新增及修改等
      let Category = await getEConfigurationByID({ id: obj.id });
      //console.log(Category);
      ruleForm.id = Category.id;
      ruleForm.evalContent = Category.evalContent;
      ruleForm.Sort = Category.sort;
      ruleForm.evalType = Category.evalType;
      ruleForm.anniu1 = true;
      ruleForm.dialogFormVisible = true; //显示出表单
    };

    let UpdateClick2 = async (obj) => {
      console.log("UpdateClick", UpdateClick2), (ruleForm.addOrUpdate = 1);

      ruleForm.id = obj.id;
      //赋值默认值弹框获取ID号，方便下一步进行新增及修改等
      let Category = await getEConfigurationByID({ id: obj.id });
      //console.log(Category);
      ruleForm.id = Category.id;
      ruleForm.evalContent = Category.evalContent;
      ruleForm.Sort = Category.sort;
      ruleForm.evalType = Category.evalType;
      ruleForm.anniu1 = true;
      ruleForm.dialogFormVisible2 = true; //显示出表单
    };
    //点击新增按钮
    let AddClick = async () => {
      //  ruleFormRef.value.resetFields();
      ruleForm.addOrUpdate = 0;
      ruleForm.evalContent = "";
      ruleForm.evalType = "1";
      ruleForm.Sort = "";
      ruleForm.id = "";
      ruleForm.anniu1 = false;
      ruleForm.dialogFormVisible2 = true; //显示出表单
    };
    //提交表单,新增或修改项目信息
    let submitForm = async (formName) => {
      let con = false; //必须这样写，下面的return false是内部的方法不走了，但是下面还是执行的
      ruleFormRef.value.validate((valid) => {
        if (!valid) {
          con = true;
          //console.log("下面的不执行了");
          return false;
        }
      });
      if (con) {
        return false;
      }
      if (ruleForm.addOrUpdate == 1) {
        //更新

        let data = {
          evalContent: ruleForm.evalContent,
          sort: ruleForm.Sort,
          id: ruleForm.id,
        };
        let res = await updateEvaluationConfiguration(data);
        ElMessage({
          showClose: true,
          message: res ? "修改成功！" : "修改失败！请重试",
          type: "warning",
        });
      } else {
        //新增
        let data = {
          evalContent: ruleForm.evalContent,
          sort: ruleForm.Sort,
          evalType: ruleForm.evalType,
        };
        let res = await addEvaluationConfiguration(data);
        ElMessage({
          showClose: true,
          message: res ? "添加成功！" : "添加失败！请重试",
          type: "warning",
        });
      }
      let res = await getEvaluationConfigurationListFun(option); //重新加载数据

      ruleForm.dialogFormVisible = false;
      ruleForm.dialogFormVisible2 = false;
    };

    let deleteObj = "";

    //点击删除按钮
    let DeleteClick = async (obj) => {
      console.log(obj);
      deleteObj = obj;
      data.deleteDialogVisible = true;
    };

    let CancelDelete = async () => {
      deleteObj = "";
      data.deleteDialogVisible = false;
    };

    //确认删除
    let ConfirmDelete = async () => {
      //deleteEvaluationConfiguration
      console.log("ConfirmDelete");
      let params = {
        id: deleteObj.id,
      };

      let res = await deleteEvaluationConfiguration(params);

      ElMessage({
        showClose: true,
        message: res == true ? "删除成功！" : "删除失败！请重试",
        type: "warning",
      });
      CancelDelete();
      getTabList();
    };

    //表单重置
    let resetForm = (formName) => {
      ruleForm.dialogFormVisible = false;
      ruleForm.dialogFormVisible2 = false;
    };

    //验证采用弹窗的方式,没做
    let btnSubmit = () => {
      // ElMessage.error({
      //   message: '姓名不能为空',
      //   type: "error",
      //   offset:130,
      //   duration:1500
      // });
    };
    return {
      data,
      getTabList,
      option,
      rules,
      UpdateClick1,
      UpdateClick2,
      ruleForm,
      btnSubmit,
      submitForm,
      resetForm,
      ruleFormRef,
      AddClick,
      DeleteClick,
      deleteObj,
      CancelDelete,
      ConfirmDelete,
      indexMethod,
    };
  },
};
</script>

<!-- 本页的css -->
<style lang="scss" scoped>
.wenzi {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 40px;
}
.table-container {
  .el-table {
    margin-bottom: 50px; // 添加下边距
  }
}
.select-box {
  display: flex;
  align-items: center;
  width: 100%;
  height: 80px;

  > div {
    width: 15%;
    display: flex;
    align-items: center;

    span {
      width: 110px;
    }

    margin-right: 25px;
  }
}

.fenye {
  margin-top: 20px;
}
</style>
