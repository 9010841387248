<template>
  <div class="container">
    <div class="top">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>客户详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="top-main">
      <span class="title">客户信息</span>
      <el-row class="mt16">
        <el-col :span="6">
          <span class="name">客户姓名：</span>
          <span class="content">{{
            memberData.memberName ? memberData.memberName : "暂无"
          }}</span>
        </el-col>
        <el-col :span="6">
          <span class="name">客户真实姓名：</span>
          <span class="content">{{
            memberData.realName ? memberData.realName : "暂无"
          }}</span>
        </el-col>
        <el-col :span="6">
          <span class="name">手机号：</span>
          <span class="content">{{
            memberData.mobile ? memberData.mobile : "暂无"
          }}</span>
        </el-col>
        <el-col :span="6">
          <span class="name">性别：</span>
          <span class="content" v-if="memberData.sex">{{
            memberData.sex === "w" ? "女" : "男"
          }}</span>
          <span class="content" v-else>暂无</span>
        </el-col>
      </el-row>
      <el-row class="mt16">
        <el-col :span="6">
          <span class="name">客户等级：</span>
          <span class="content">{{
            memberData.memberLevel ? memberData.memberLevel : "暂无"
          }}</span>
        </el-col>
        <el-col :span="6">
          <span class="name">新老客：</span>
          <span class="content">{{
            memberData.isNew == 0 ? "老客" : "新客"
          }}</span>
        </el-col>
        <el-col :span="6">
          <span class="name">所属门店：</span>
          <span class="content">{{
            memberData.shopName ? memberData.shopName : "暂无"
          }}</span>
        </el-col>
        <el-col :span="6">
          <span class="name">进店渠道：</span>
          <span class="content">{{
            memberData.memberChannelName ? memberData.memberChannelName : "暂无"
          }}</span>
        </el-col>
      </el-row>
      <el-row class="mt16">
        <el-col :span="6">
          <span class="name">创建时间：</span>
          <span class="content">{{
            memberData.createTime ? memberData.createTime : "暂无"
          }}</span>
        </el-col>
      </el-row>
    </div>
    <div class="tab">
      <div
        v-for="(item, index) in tanData"
        :key="index"
        @click="handleTabs(item, index)"
      >
        <span :class="tabIndex == index ? 'activeClass' : 'defaultClass'">{{
          item.tab
        }}</span>
        <div v-show="tabIndex == index" class="bar"></div>
      </div>
    </div>
    <div class="tab-main">
      <Information
        v-if="this.checkType == 'jbxx'"
        :info="memberData"
      ></Information>
      <CardInformation
        v-if="this.checkType == 'ckxx'"
        @lookLog="lookLog"
      ></CardInformation>
      <ExpenseCalendar
        v-if="this.checkType == 'xhjl'"
        :memberId="memberId"
      ></ExpenseCalendar>
    </div>
  </div>
</template>

<script>
import { memberDetail } from "@/http/member";
import { Information, CardInformation, ExpenseCalendar } from "./components";
import { ElMessage } from "element-plus";
export default {
  components: {
    Information,
    CardInformation,
    ExpenseCalendar,
  },
  data() {
    return {
      tanData: [
        { tab: "基础信息", type: "jbxx" },
        { tab: "持卡信息", type: "ckxx" },
        { tab: "消耗记录", type: "xhjl" },
      ],
      tableArr: [],
      tabIndex: 0,
      memberData: {},
      checkType: "jbxx",
      loading: false,
      memberId: "",
    };
  },
  created() {
    let id = this.$route.query.memberId;
    this.memberId = id;
    this.getConsultDetailPage(id);
  },
  methods: {
    handleTabs(val, i) {
      this.tabIndex = i;
      this.checkType = val.type;
    },
    async getConsultDetailPage(id) {
      const { code, msg, data } = await memberDetail({ memberId: id });
      if (code != null && code != 0) {
        ElMessage.error(msg || "出错了");
        return;
      }
      this.memberData = data;
    },
    // 查看消费记录
    lookLog(val) {
      this.checkType = "xhjl";
      this.tabIndex = 2;
      val ? (this.memberId = val.mcardId) : "";
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding: 20px;
  box-sizing: border-box;
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    background-color: #fff;
    padding: 0 18px;
    box-sizing: border-box;
    border-bottom: 1px solid rgba(0, 0, 0, 0.07);
  }
  .top-main {
    padding: 10px 18px;
    box-sizing: border-box;
    background-color: #fff;
    .title {
      font-size: 14px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.85);
    }
    .name {
      font-size: 14px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
    }
    .content {
      font-size: 14px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.65);
    }
    .top-main-item {
      display: flex;
      align-items: center;
      .item {
        width: 164px;
        height: 80px;
        background: #f5f5f5;
        padding: 16px;
        box-sizing: border-box;
        margin-right: 15px;
        > span {
          font-size: 11px;
          font-weight: 400;
          color: #000000;
        }
        .number {
          margin-top: 8px;
        }
      }
    }
  }
  .tab {
    display: flex;
    align-items: center;
    background-color: #fff;
    height: 64px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.07);
    > div {
      width: 118px;
      height: 100%;
      text-align: center;
      line-height: 64px;
      position: relative;
      cursor: pointer;
      .bar {
        position: absolute;
        left: 50%;
        bottom: 4px;
        transform: translateX(-50%);
        width: 46px;
        height: 5px;
        border-radius: 2px;
        background: #6ce1d5;
      }
      .defaultClass {
        font-size: 16px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
      }
      .activeClass {
        font-size: 16px;
        font-weight: 500;
        color: #6ce1d5;
      }
    }
  }
  .tab-main {
    background-color: #fff;
    padding: 0px 20px;
    box-sizing: border-box;
  }
}

.mt16 {
  margin-top: 16px;
}
</style>
