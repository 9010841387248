import $http from "./index";
import { PLATFROM_CONFIG } from "../../public/config";
let base = PLATFROM_CONFIG.baseURL;

export const getEvaluationConfigurationList = () => {
  return $http.get(
    `/app/info/evaluationconfiguration/back/getEvalConfiguration`,
    {}
  );
};

//删除
export const deleteEvaluationConfiguration = (data) => {
  return $http.get(`/app/info/evaluationconfiguration/back/delete`, {
    params: data,
  });
};

// 通过id获取投诉
export const getEConfigurationByID = (data) => {
  return $http.get(`/app/info/evaluationconfiguration/back/detail`, {
    params: data,
  });
};

//updateComplaintType
export const updateEvaluationConfiguration = (data) => {
  return $http.post(`/app/info/evaluationconfiguration/back/update`, data);
};

//addComplaintType
export const addEvaluationConfiguration = (data) => {
  return $http.post(`/app/info/evaluationconfiguration/back/new`, data);
};
