<template>
  <div>
    <div class="select-box">
      <!-- 1.筛选条件 -->
      <div class="120px">
        <span>订单号:</span>
        <el-input
          v-model="option.orderNumber"
          clearable
          placeholder="搜索订单号"
        />
      </div>
      <div>
        <span>服务名称:</span>
        <el-input
          v-model="option.serviceName"
          clearable
          placeholder="搜索服务名称"
        />
      </div>
      <div>
        <span>客户ID:</span>
        <el-input
          v-model="option.memberID"
          clearable
          placeholder="搜索客户ID"
        />
      </div>
      <div>
        <span>客户姓名:</span>
        <el-input
          v-model="option.memberName"
          clearable
          placeholder="搜索客户姓名"
        />
      </div>
      <div>
        <span>服务人员:</span>
        <el-input
          v-model="option.servicePeople"
          clearable
          placeholder="搜索服务人员"
        />
      </div>
    </div>
    <div class="select-box">
      <div>
        <el-date-picker
          v-model="option.StartTime"
          type="date"
          value-format="YYYY-MM-DD 00:00:00"
          placeholder="开始日期"
        >
        </el-date-picker>
      </div>
      <div>
        <el-date-picker
          v-model="option.EndTime"
          value-format="YYYY-MM-DD 23:59:59"
          type="date"
          placeholder="结束日期"
        >
        </el-date-picker>
      </div>
      <div>
        <el-button @click="getTabList" icon="el-icon-search" type="primary"
          >查 询</el-button
        >
        <el-button @click="exportExcel()" icon="el-icon-download" type="primary"
          >导 出</el-button
        >
      </div>
    </div>
    <!--2. table表格的内容-->
    <el-table
      id="table"
      border
      stripe
      :data="data.table"
      style="width: 100%"
      highlight-current-row
      @current-change="currentChange"
    >
      <!--<el-table-column prop="id" label="ID" v-if="false"> </el-table-column>-->
      <!--隐藏列-->
      <el-table-column
        type="index"
        :index="indexMethod"
        width="50"
        style="text-align: center"
      >
      </el-table-column>
      <el-table-column prop="serviceName" label="服务名称"> </el-table-column>
      <el-table-column prop="buckleNum" label="划扣次数"> </el-table-column>
      <el-table-column prop="servicePeople" label="服务人员"> </el-table-column>
      <el-table-column prop="orderNumber" label="订单号"></el-table-column>
      <el-table-column
        prop="overalEvaluation"
        label="整体评价"
      ></el-table-column>
      <el-table-column prop="creationTime" label="提交时间"> </el-table-column>
      <el-table-column prop="memberName" label="客户姓名"> </el-table-column>
      <el-table-column prop="memberID" label="客户ID"> </el-table-column>
      <el-table-column prop="remark" label="备注"> </el-table-column>
      <el-table-column fixed="right" label="操作" width="200">
        <!--操作固定列 -->
        <template #default="scope">
          <el-button
            size="small"
            icon="el-icon-edit"
            @click="getClick(scope.row)"
            type="primary"
            >查看</el-button
          >
          <el-button
            size="small"
            v-if="scope.row.isUpload == false"
            icon="el-icon-upload2"
            @click="getClick1(scope.row)"
            type="primary"
            >重推</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <!-- 3.分页 -->
    <el-pagination
      class="fenye"
      @current-change="handleCurrentChange"
      :current-page="option.page"
      :page-size="8"
      prev-text="&nbsp;&nbsp;&nbsp;上一页&nbsp;&nbsp;&nbsp;"
      next-text="&nbsp;&nbsp;&nbsp;下一页&nbsp;&nbsp;&nbsp;"
      background
      layout="total, prev, pager, next, jumper"
      :total="data.total"
    >
    </el-pagination>

    <el-dialog
      width="52%"
      title="服务列表详情"
      v-model="ruleForm.dialogFormVisible"
      :show-close="true"
    >
      <el-table
        id="table3"
        border
        stripe
        :rules="rules"
        :data="data.tables"
        ref="ruleFormRef"
        abel-width="150px"
        class="demo-ruleForm"
      >
        <el-table-column prop="evaluationItems" label="评价项">
        </el-table-column>
        <el-table-column prop="satisfaction" label="满意度"> </el-table-column>
      </el-table>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="resetForm('ruleForm')"
            >关 闭</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<!-- 本页的js -->
<script>
import { reactive } from "vue";
import {
  getEvaluationdetail,
  getServiceEvaluation,
  ExportEvaluation,
  uploadEvalConfig,
} from "../http/serviceevaluation";
import { FullTimeFormat } from "../utils/common";
import * as XLSX from "xlsx";
import FileSaver from "file-saver";
import { ElMessage } from "element-plus";

//import { ElMessage } from "element-plus";
export default {
  name: "Complaint",
  setup() {
    //声明数据
    let data = reactive({
      table: [],
      tables: [],
      pages: 0,
      total: 0,
      srcList: [""],
    });
    let resetForm = (formName) => {
      ruleForm.dialogFormVisible = false;
    };
    //声明查询条件
    let option = reactive({
      serviceName: "",
      servicePeople: "",
      page: 1,
      pageSize: 8,
      memberID: "",
      memberName: "",
      orderNumber: "",
      creationTime: "",
      StartTime: "",
      EndTime: "",
    });
    //查询的表单参数//////////////////////////////////////////////////
    let ruleForm = reactive({
      dialogFormVisible: false,
      evaluationItems: "",
      satisfaction: "",
      data: [],
      content: "",
    });
    //点击查看按钮,弹框，默认把原来的数据显示出来
    let getClick = async (obj) => {
      ruleForm.id = obj.id;
      //赋值默认值弹框获取ID号，方便下一步进行新增及修改等
      let banner = await getEvaluationdetail({ evaluationID: obj.id });
      console.log(ruleForm);
      console.log("banner", banner);
      data.tables = banner;

      ruleForm.dialogFormVisible = true; //显示出表单
    };
    let indexMethod = (index) => {
      return index + (option.page - 1) * option.pageSize + 1;
    };

    //获取订单列表方法
    let getServiceEvaluationFun = async (obj) => {
      let arr = await getServiceEvaluation(obj);
      data.total = arr.totalCount;
      //格式化日期
      arr.items.filter((item) => {
        item.creationTime = FullTimeFormat(item.creationTime);
        item.payTime = FullTimeFormat(item.payTime);
      });
      data.table = arr.items;
    };
    //初始化时，获取初始化数据
    getServiceEvaluationFun({ Page: 1, PageSize: 8, payStatus: 3 });
    //点击页码获取数据
    let handleCurrentChange = (index) => {
      option.page = index;
      getServiceEvaluationFun(option);
    };
    //查询方法,此处需要根据条件到后台进行筛选
    let getTabList = async () => {
      option.page = 1;
      //console.log(option);
      getServiceEvaluationFun(option);
    };
    //导出表格为excel
    let exportExcel = () => {
      //开始导出
      exportData();
    };
    let exportData = () => {
      ExportEvaluation(option).then((res) => {
        let reg = /\\/g;
        let replaceAfter = res.eValues.replace(reg, "");
        var jsondata = JSON.parse(replaceAfter);
        console.log(jsondata);
        let time = new Date().getTime();
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(jsondata);
        XLSX.utils.book_append_sheet(workbook, worksheet, "data");
        XLSX.writeFile(workbook, time + ".xlsx");
      });
    };

    //重推服务评价到主业务系统
    let getClick1 = async (obj) => {
      console.log("重推");
      let params = {
        id: obj.id,
      };

      let res = await uploadEvalConfig(params);
      ElMessage({
        showClose: true,
        message: res == true ? "重推成功！" : "重推失败！请重试或联系管理员",
        type: "warning",
      });
      await getServiceEvaluationFun({ Page: 1, PageSize: 8, payStatus: 3 });
    };

    return {
      data,
      handleCurrentChange,
      getTabList,
      option,
      getClick,
      getClick1,
      ruleForm,
      indexMethod,
      resetForm,
      exportExcel,
      exportData,
    };
  },
};
</script>

<!-- 本页的css -->
<style lang="scss" scoped>
.select-box {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 25px;
  > div {
    width: 14%;
    display: flex;
    align-items: center;
    span {
      width: 120px;
    }
    time {
      width: 20%;
    }
    margin-right: 20px;
  }
}
.fenye {
  margin-top: 20px;
}
</style>
