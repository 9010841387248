<template>
  <div class="wenzi">注意事项配置</div>
  <div class="select-box">
    <div>
      <el-button type="primary" @click="submitForm" id="but"> 保存 </el-button>
    </div>
    <el-row>
      <el-col :span="24">
        <div id="div1">
          <span id="top">注意事项配置</span>
          <span id="dom">内容：</span>
          <el-input
            id="inp"
            v-model="ruleForm.evalContent"
            :rows="2"
            type="textarea"
            style="margin-left: 80px"
          >
          </el-input>
          <div style="margin-top: 50px">
            <span id="dom2">底部文字：</span>
            <el-input
              id="inp2"
              v-model="ruleForm2.evalContent"
              :rows="2"
              type="textarea"
              style="margin-left: 80px"
            ></el-input>
          </div>
          <div style="margin-top: 50px">
            <span id="dom3">电话：</span>
            <el-input
              id="inp3"
              style="margin-left: 80px"
              v-model="ruleForm3.evalContent"
            >
            </el-input>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<!-- 本页的js -->
<script>
import { ref, reactive } from "vue";
import {
  getNoteConfigurationList,
  getNote7ConfigurationList,
  getNote8ConfigurationList,
  updateEvalContent,
} from "../http/noteconfiguration";
import { ElMessage } from "element-plus";

export default {
  name: "ServicePhone",
  setup() {
    const ruleFormRef = ref(null);
    let ruleForm = reactive({
      evalContent: "",
      evalType: "",
    });
    let ruleForm2 = reactive({
      evalContent: "",
      evalType: "",
    });
    let ruleForm3 = reactive({
      evalContent: "",
      evalType: "",
    });
    //声明查询条件
    let option = reactive({});
    let getNoteConfiguration = async (option) => {
      let EvalContent = await getNoteConfigurationList(option);
      ruleForm.evalContent = EvalContent[0].evalContent;
      ruleForm.sort = EvalContent[0].sort;
    };

    let getNoteConfiguration2 = async (option) => {
      let EvalContent = await getNote7ConfigurationList(option);
      ruleForm2.evalContent = EvalContent[0].evalContent;
      ruleForm2.sort = EvalContent[0].sort;
    };
    let getNoteConfiguration3 = async (option) => {
      let EvalContent = await getNote8ConfigurationList(option);
      ruleForm3.evalContent = EvalContent[0].evalContent;
      ruleForm3.sort = EvalContent[0].sort;
    };

    // 初始化时，获取初始化数据
    getNoteConfiguration(option);
    getNoteConfiguration2(option);
    getNoteConfiguration3(option);
    const submitForm = async () => {
      if (
        ruleForm.evalContent == "" ||
        ruleForm.evalContent == null ||
        ruleForm.evalContent == undefined
      ) {
        ElMessage.error({
          message: "内容不能为空！",
          type: "error",
          offset: 130,
          duration: 1500,
        });
        return false;
      } else if (
        ruleForm2.evalContent == "" ||
        ruleForm2.evalContent == null ||
        ruleForm2.evalContent == undefined
      ) {
        ElMessage.error({
          message: "底部文字不能为空！",
          type: "error",
          offset: 130,
          duration: 1500,
        });
        return false;
      } else if (
        ruleForm3.evalContent == "" ||
        ruleForm3.evalContent == null ||
        ruleForm3.evalContent == undefined
      ) {
        ElMessage.error({
          message: "电话不能为空！",
          type: "error",
          offset: 130,
          duration: 1500,
        });
        return false;
      }
      let data = {
        evalContent: ruleForm.evalContent,
        evalType: 2,
      };
      let data2 = {
        evalContent: ruleForm2.evalContent,
        evalType: 7,
      };
      let data3 = {
        evalContent: ruleForm3.evalContent,
        evalType: 8,
      };
      await updateEvalContent(data);
      await updateEvalContent(data2);
      await updateEvalContent(data3);
      ElMessage.success({
        message: "保存成功！",
        type: "success",
        offset: 130,
        duration: 2000,
      });
    };

    return {
      ruleForm,
      ruleForm2,
      ruleForm3,
      submitForm,
      ruleFormRef,
      option,
    };
  },
};
</script>

<style lang="scss" scoped>
.select-box {
  span {
    width: 170px;
  }
}

#top {
  padding: 25px;
  display: block;
  width: 100%;
  background: #f0f0f0;
  text-align: left;
  color: black;
  font-weight: bold;
}

#but {
  position: relative;
  top: 20px;
  left: 1000px;
}

#div1 {
  position: relative;
  width: 1000px;
  top: 50px;
}

.el-row {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

#dom {
  position: relative;
  top: 30px;
}
#dom2 {
  position: relative;
  top: 30px;
}
#dom3 {
  position: relative;
  top: 30px;
}
</style>
