<template>
  <div class="ExpenseCalendar">
    <!-- <div class="calendar_top">
      <div class="left">
        <el-date-picker
          v-model="date"
          type="daterange"
          range-separator="至"
          :clearable="false"
          @change="changeDate"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
        <el-select
          v-model="reqVo.memberCardId"
          placeholder="选择卡名称"
          class="w120 ml10"
        >
          <el-option
            v-for="item in options"
            :key="item.cardId"
            :label="item.cardName"
            :value="item.cardId"
          ></el-option>
        </el-select>
      </div>
      <div class="right">
        <el-input
          style="width: 177px"
          v-model.trim="reqVo.memberCardItemName"
          @keyup.enter.native="seachCalendar"
          placeholder="输入项目名称搜索"
        ></el-input>
        <el-button class="btn ml15" @click="resetSearch">重置</el-button>
        <el-button class="btn search_btn ml15" @click="seachCalendar"
          >搜索</el-button
        >
      </div>
    </div> -->
    <div class="table_box">
      <el-table
        :data="tableData"
        style="width: 100%"
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
      >
        <el-table-column
          prop="relationName"
          label="卡名称"
          align="left"
          width="240"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column show-overflow-tooltip label="项目名称" prop="itemName">
        </el-table-column>
        <el-table-column
          prop="shopName"
          show-overflow-tooltip
          label="消费门店"
        ></el-table-column>
        <el-table-column
          prop="typeName"
          show-overflow-tooltip
          label="类型"
        ></el-table-column>
        <el-table-column
          prop="quantity"
          show-overflow-tooltip
          label="数量"
        ></el-table-column>
        <el-table-column
          prop="tradeTime"
          label="交易时间"
          show-overflow-tooltip
          width="170"
        ></el-table-column>
        <el-table-column
          prop="payment"
          show-overflow-tooltip
          label="消费金额"
        ></el-table-column>
        <el-table-column
          prop="payTypeDetail"
          show-overflow-tooltip
          label="支付详情"
        ></el-table-column>
        <!-- <el-table-column
          show-overflow-tooltip
          label="服务人员"
          prop="serverStaffName"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          label="销售人员"
          prop="saleStaffNames"
        >
        </el-table-column> -->
      </el-table>
    </div>
    <div class="page_box">
      <el-pagination
        :background="true"
        @current-change="handleCurrent"
        :page-size="reqVo.limit"
        :current-page="reqVo.page"
        layout="prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { consumeRecord } from "@/http/member";
import { ElMessage } from "element-plus";
export default {
  props: {
    memberId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      options: [],
      value: "",
      date: "",
      input: "",
      tableData: [],
      total: 20,
      loading: false,
      reqVo: {
        memberId: this.memberId,
        page: 1,
        limit: 10,
      },
      searchReqVo: {
        businessCode: "PC_MEMBER_DETAIL",
        current: 1,
        memberId: this.$route.query.id,
        searchKey: "",
        size: 100,
      },
    };
  },
  watch: {},
  created() {
    this.getList();
  },
  methods: {
    seachCalendar() {
      this.getList();
    },
    changeDate() {
      this.reqVo.startDate = this.date[0];
      this.reqVo.endDate = this.date[1];
    },
    resetSearch() {
      this.reqVo.startDate = "";
      this.reqVo.endDate = "";
      this.reqVo.memberCardItemName = "";
      this.reqVo.memberCardId = "";
      this.reqVo.page = 1;
      this.date = [];
      this.getList();
    },
    // 分页
    handleCurrent(e) {
      this.reqVo.page = e;
      this.getList();
    },
    // table数据
    getList() {
      this.loading = true;
      consumeRecord(this.reqVo)
        .then((res) => {
          this.loading = false;

          const { code, msg, data, count } = res;

          if (code != null && code != 0) {
            ElMessage.error(msg || "出错了");
            return;
          }
          this.tableData = data;
          this.total = count;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
  },
};
</script>

<style>
.el-table td,
.el-table th.is-leaf {
  border-bottom: 1px dashed #ebeef5;
}
</style>
<style lang="scss" scoped>
.ExpenseCalendar {
  padding: 20px 24px;
  .w120 {
    width: 200px;
  }
  .ml10 {
    margin-left: 10px;
  }
  .ml15 {
    margin-left: 15px;
  }
  .search_btn {
    background: linear-gradient(136deg, #48e2d0 0%, #3cdbcb 100%);
    color: #fff;
  }
  .calendar_top {
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .page_box {
    margin-top: 20px;
    text-align: right;
  }
}
</style>
