<template>
  <div class="transpondPage">
    <!-- table表格 -->
    <el-table stripe :data="transpondTableList" :header-cell-style="{ background: 'rgb(242, 242, 242)', color: '#333' }" v-loading="tableLoading" element-loading-text="拼命加载中...">
      <el-table-column label="序号" type="index" width="50" />
      <el-table-column prop="transferType" label="转发类型" show-overflow-tooltip />
      <el-table-column prop="transferDescribe" label="转发说明" show-overflow-tooltip />
      <el-table-column prop="lastModifyTime" label="最近操作时间" show-overflow-tooltip />
      <el-table-column label="操作" width="150">
        <template #default="scope">
          <el-button type="primary" size="small" @click="editDataFun(scope.row)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 配置 -->
    <el-dialog v-model="dialogVisible" title="转发配置" :width="500" center @close="closeDialog">
      <div>
        <el-form :model="formData" :rules="rules" ref="ruleFormRef">
          <el-form-item label="转发说明" :label-width="100" prop="transferDescribe">
            <el-input v-model="formData.transferDescribe" :maxlength="25" type="textarea" show-word-limit placeholder="请输入转发说明" />
          </el-form-item>
          <el-form-item label="转发图片" :label-width="100" prop="imgUrl">
            <el-upload
              action=""
              :limit="upLoadObj.limit" 
              :multiple="upLoadObj.multiple"
              :class="{ uploadBox_hide: hideUploadEdit }"
              accept=".jpg,.jpeg" 
              list-type="picture-card" 
              :file-list="formData.transpondFileList" 
              :beforeUpload="beforeUpload"
              :on-preview="handlePreview" 
              :on-remove="handleRemove"
              :http-request="handleUpload">
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog v-model="imgDialogVisible">
              <el-image :src="formData.imgUrl"></el-image>
            </el-dialog>
            <div class="imgPs">仅支持.jpg、.jpeg格式的图片，图片长宽比5:4，大小不超过2MB</div>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="closeSubmit('formData')">取消</el-button>
          <el-button type="primary" @click="sureSubmit('formData')" :loading="setLoading">确定</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script setup name="transpond">
import { ref, reactive, onMounted } from 'vue'
import { ElMessage } from "element-plus"
import { useRouter } from "vue-router"
import { putToOSS, getFileNameUUID, getFileExtensions } from "@/http/oss";

import { transpondList, transpondDetail, updateTranferConfig } from "@/http/transpond"

const ruleFormRef = ref(null); // this

let dialogVisible = ref(false)
let imgDialogVisible = ref(false)
let hideUploadEdit = ref(false)
let upLoadObj = reactive({
  limit: 1,
  fileList: [],
  multiple: false
})
const formData = reactive({
  transferDescribe: '',
  imgUrl: '',
  transpondFileList: []
})
const rules = reactive({
  transferDescribe: [
    { required: true, message: '转发说明不能为空', trigger: 'blur' },
    { min: 5, max: 25, message: '转发说明文字限制5-25个字', trigger: 'blur' }
  ],
  imgUrl: [
    { required: true, message: '转发图片不能为空', trigger: 'change' }
  ]
})
onMounted(() => {
  getTabList()
})
// 列表数据
let tableLoading = ref(false)
let transpondTableList = ref([])
function getTabList() {
  tableLoading.value = true
  transpondList({}).then(res => {
    tableLoading.value = false
    transpondTableList.value = res.data
  }).catch(() => {
    tableLoading.value = false
  })
}
let selectId = ref('')
// 编辑
function editDataFun(row) {
  formData.transferDescribe = ''
  formData.imgUrl = ''
  upLoadObj.fileList = []
  formData.transpondFileList = []
  selectId.value = row.id
  transpondDetail({ id: row.id}).then(res => {
    formData.transferDescribe = res.data.transferDescribe
    formData.imgUrl = res.data.transferImg
    formData.transpondFileList.push({ name: '转发图片', url: res.data.transferImg})
    upLoadObj.fileList.push({ name: '转发图片', url: res.data.transferImg})
    hideUploadEdit.value = upLoadObj.fileList.length >= upLoadObj.limit;
    dialogVisible.value = true
  })
}
// 校验图片
function beforeUpload(file) {
  const fileExtensions = getFileExtensions(file.name) == ".jpg" || getFileExtensions(file.name) == ".jpeg";
  const max2M = file.size / 1024 / 1024 < 2;
  if (!fileExtensions) {
    ElMessage.warning({
      message: "上传图片类型仅支持.jpg，.jpeg格式",
      duration: 2000
    });
  }
  if (!max2M) {
    ElMessage.warning({
      message: "上传图片大小不能超过 2MB",
      duration: 2000
    });
  }
  return fileExtensions && max2M;
};
// 上传
function handleUpload(option) {
  let objName = "transpondImg/" + getFileNameUUID() + getFileExtensions(option.file.name);
    putToOSS(`flieName/${objName}`, option.file).then(res => {
      formData.imgUrl = res.url;
      upLoadObj.fileList.push({ name: option.file.name, url: res.url})
      hideUploadEdit.value = upLoadObj.fileList.length >= upLoadObj.limit;
  });
};
// 删除
function handleRemove(file, fileList) {
  // let index = upLoadObj.fileList.findIndex(val => {
  //   return val.name == file.name
  // })
  upLoadObj.fileList.splice(0, 1)
  formData.imgUrl = ''
  hideUploadEdit.value = upLoadObj.fileList.length >= upLoadObj.limit;
}
// 预览
function handlePreview() {
  imgDialogVisible.value = true
}
// 取消
function closeSubmit(form) {
  ruleFormRef.value.resetFields()
  dialogVisible.value = false
}
function closeDialog() {
  ruleFormRef.value.resetFields()
}
// 提交
let setLoading = ref(false)
async function sureSubmit(form) {
  if (!form) return
  ruleFormRef.value.validate(valid => {
    if (valid) {
      let data = {
        id: selectId.value,
        transferImg: formData.imgUrl,
        transferDescribe: formData.transferDescribe
      }
      setLoading.value = true
      updateTranferConfig(data).then(res => {
        setLoading.value = false
        if(res.code == 200) {
          getTabList()
          dialogVisible.value = false
        }
      }).catch(() => { setLoading.value = false })
    }
  })
}
</script>

<style lang='scss' scoped>
  ::v-deep .uploadBox_hide .el-upload--picture-card {
    display: none !important;
  }
  ::v-deep .el-upload-list__item {
    transition: none !important;
  }
  .transpondPage {
    .imgPs {
      color: red;
      font-size: 12px;
    }
  }
</style>