import $http from "./index";
import { PLATFROM_CONFIG } from "../../public/config";
let base = PLATFROM_CONFIG.baseURL;

//获取投诉列表
export const getComplaintList = (data) => {
  return $http.get(`/app/business/Complaint/back/pagelist`, {
    params: data,
  });
};

// 获取投诉详情
export const getComplaintDetail = (data) => {
  return $http.get(`/app/business/Complaint/back/detail`, {
    params: data,
  });
};

export const getComplaintTypeList = (data) => {
  return $http.get(`/app/business/complaintyype/back/pagelist`, {
    params: data,
  });
};

//删除投诉类型
export const deleteComplaintType = (data) => {
  return $http.get(`/app/business/complaintyype/back/delete`, {
    params: data,
  });
};

// 通过id获取投诉类型
export const getComplaintTypeByID = (data) => {
  return $http.get(`/app/business/complaintyype/back/detail`, {
    params: data,
  });
};

//updateComplaintType
export const updateComplaintType = (data) => {
  return $http.post(`/app/business/complaintyype/back/update`, data);
};

//addComplaintType
export const addComplaintType = (data) => {
  return $http.post(`/app/business/complaintyype/back/new`, data);
};

// 导出客诉列表
export const exportComplaint = (data) => {
  return $http.get(`/app/business/Complaint/back/complaintExport`, {
    params: data,
  });
};

// 更新跟进人
export const updateFollowUp = (data) => {
  return $http.post(`/app/business/Complaint/back/UpdateFollowUp`, data);
};
