<template>
  <el-breadcrumb separator-class="el-icon-arrow-right">
    <el-breadcrumb-item :to="{ path: '/VipManage' }" style="font-size: 15px"
      >返回</el-breadcrumb-item
    >
    <el-breadcrumb-item></el-breadcrumb-item>
  </el-breadcrumb>
  <br />
  <!--    <div >-->
  <!--        <span  style="font-size: 18px;font-weight: bold;">等级会员管理-配置权益</span>-->
  <!--    </div>-->
  <div class="select-box">
    <div>
      <el-button type="primary" @click="submitForm" id="but"> 保存 </el-button>
    </div>
    <el-row>
      <el-col :span="24">
        <div id="div1">
          <span id="top">会员简介</span>
          <br />
          <QuillEditor
            ref="quillEditorRef"
            v-model="ruleForm.evalContent"
          ></QuillEditor>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<!-- 本页的js -->
<script>
import { ref, reactive, nextTick } from "vue";
import QuillEditor from "../components/QuillEditor";
import { getvipblurb, updateEvalContent } from "../http/noteconfiguration";
import { ElMessage } from "element-plus";

export default {
  components: {
    QuillEditor,
  },
  name: "vipblurbtext",
  setup() {
    const ruleFormRef = ref(null);
    const quillEditorRef = ref(null);
    let ruleForm = reactive({
      evalContent: "",
      evalType: "",
    });
    //声明查询条件
    let option = reactive({});
    let getNoteConfiguration = async (option) => {
      let EvalContent = await getvipblurb(option);
      ruleForm.evalContent = EvalContent[0].evalContent;
      ruleForm.sort = EvalContent[0].sort;
      nextTick(() => {
        quillEditorRef.value.$refs["quill-editor"].setHTML(
          ruleForm.evalContent
        );
      });
    };

    // 初始化时，获取初始化数据
    getNoteConfiguration(option);
    const submitForm = async () => {
      if (
        ruleForm.evalContent == "" ||
        ruleForm.evalContent == null ||
        ruleForm.evalContent == undefined
      ) {
        ElMessage.error({
          message: "内容不能为空！",
          type: "error",
          offset: 130,
          duration: 1500,
        });
        return false;
      }
      let content = quillEditorRef.value.entity.content;
      ruleForm.evalContent = content;
      let data = {
        evalContent: ruleForm.evalContent,
        evalType: 10,
      };
      await updateEvalContent(data);
      ElMessage.success({
        message: "保存成功！",
        type: "success",
        offset: 130,
        duration: 2000,
      });
      getNoteConfiguration(option);
    };

    return {
      ruleForm,
      submitForm,
      quillEditorRef,
      ruleFormRef,
      option,
    };
  },
};
</script>

<style lang="scss" scoped>
.select-box {
  span {
    width: 170px;
  }
}

#top {
  padding: 25px;
  display: block;
  width: 100%;
  background: #f0f0f0;
  text-align: left;
  color: black;
  font-weight: bold;
}

#but {
  position: relative;
  top: 20px;
  left: 1000px;
}

#div1 {
  position: relative;
  width: 1000px;
  top: 50px;
}

.el-row {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

#dom {
  position: relative;
  top: 30px;
}
#dom2 {
  position: relative;
  top: 30px;
}
#dom3 {
  position: relative;
  top: 30px;
}
</style>
