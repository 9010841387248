<template>
  <el-dialog :title="dialogTitle" v-model="dialogVisible" width="700px">
    <el-form
      ref="formRef"
      :model="formData"
      size="small"
      :rules="formRules"
      label-width="110px"
      v-loading="formLoading"
    >
      <el-form-item label="类型标签" prop="dictLabel">
        <el-input
          v-model.trim="formData.dictLabel"
          placeholder="请输入类型标签，如售前"
          clearable
          maxlength="50"
          @blur="
            () => (formData.dictLabel = formData.dictLabel.replace(/\s/g, ''))
          "
        />
      </el-form-item>
      <el-form-item label="类型键值" prop="dictValue">
        <el-input
          v-model.trim="formData.dictValue"
          placeholder="请输入类型键值，如pre-sales"
          clearable
          maxlength="50"
          :disabled="formType == 'update'"
          @input="
            (value) => (formData.dictValue = value.replace(/[^\w_-]/g, ''))
          "
        />
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="submitForm" type="primary" :disabled="formLoading"
        >确定</el-button
      >
      <el-button @click="dialogVisible = false">取 消</el-button>
    </template>
  </el-dialog>
</template>
<script setup lang="js">
import {
  updateConsultationType,
  createConsultationType,
} from "../../http/consultation";
import { nextTick, reactive, ref } from "vue";
import { ElMessage, ElMessageBox } from 'element-plus'
import { FullTimeFormat } from "../../utils/common.js";

/** 咨询类型管理表单 **/
defineOptions({ name: "ConsultationTypeForm" });

const dialogVisible = ref(false); // 弹窗的是否展示
const dialogTitle = ref(''); // 弹窗的标题
const formLoading = ref(false); // 表单的加载中：1）修改时的数据加载；2）提交的按钮禁用
const formType = ref('') // 表单类型：create - 新增；update - 修改
const formData = ref({
});
const formRules = reactive({
  dictLabel: [{ required: true, message: "类型标签不能为空", trigger: "blur" }],
  dictValue: [{ required: true, message: "类型键值不能为空", trigger: "blur" }],
});
const formRef = ref(); // 表单 Ref

/** 打开弹窗 */
const open = async (type, row) => {
  dialogVisible.value = true;
  formType.value = type
  dialogTitle.value = type == 'create' ? '新增' : '修改'
  resetForm();
  formLoading.value = true;
  if (row) {
    formData.value = {
      id: row.id,
      dictLabel: row.key,
      dictValue: row.value,
    }
  }
  formLoading.value = false;
};
defineExpose({ open }); // 提供 open 方法，用于打开弹窗

/** 提交表单 */
const emit = defineEmits(["success"]); // 定义 success 事件，用于操作成功后的回调

const submitForm = async () => {
  // 校验表单
  if (!formRef.value) return;
  const valid = await formRef.value.validate();
  if (!valid) return;
  // 提交请求
  formLoading.value = true;
  try {
    if(formType.value == 'create') {
      const {code, message} = await createConsultationType(formData.value)
      if (code != 200) {
        ElMessage.error('新增失败：' + message)
        return
      }
      ElMessage.success('新增成功')
    } else {
     const {code, message} = await updateConsultationType(formData.value)
     if (code != 200) {
        ElMessage.error('修改失败：' + message)
        return
      }
     ElMessage.success('修改成功')
    }
    dialogVisible.value = false
    // 发送操作成功的事件
    emit("success");
  } finally {
    formLoading.value = false;
  }
};

/** 重置表单 */
const resetForm = () => {
  formData.value = {
  id: undefined,
  dictLabel: undefined,
  dictValue: undefined
};
  formRef.value?.resetFields();
};
</script>

<style lang="scss" scoped></style>
