<template>
  <div>
    <!-- 1.筛选条件 -->
    <div class="select-box">
      <div>
        <span>商品名称:</span>
        <el-input v-model="option.tradeName" clearable placeholder="请输入商品名称"></el-input>
      </div>
      <div>
        <span>分类:</span>
        <el-select v-model="option.classification" clearable placeholder="请选择分类">
          <el-option v-for="item in data.ListData" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </div>
      <div>
        <el-button @click="getTabList" icon="el-icon-search" type="primary">查 询</el-button>
        <!--<el-button @click="ToSetting" icon="el-icon-s-operation" type="primary">分类设置</el-button>-->
        <el-button icon="el-icon-document-add" @click="AddClick()" type="primary">新增商品</el-button>
        <el-button icon="el-icon-document-add" @click="UpdateClickcoup()" type="primary">积分说明配置</el-button>
      </div>
    </div>
    <!--2. table  -->
    <el-config-provider :locale="zhCn">
      <el-table border stripe :data="data.table" style="width: 100%" :header-cell-style="{ background: 'rgb(242, 242, 242)', color: '#333' }" v-loading="tableLoading" element-loading-text="拼命加载中...">
        <el-table-column type="index" :index="indexMethod" width="50" style="text-align: center"></el-table-column>
        <el-table-column prop="tradeName" label="商品名称" show-overflow-tooltip></el-table-column>
        <el-table-column prop="redeemPoints" label="兑换积分" width="95"></el-table-column>
        <el-table-column prop="productStatus" label="商品状态">
          <template v-slot:default="scope">
            <span v-if="scope.row.productStatus === '0'">启动</span>
            <span v-else>禁用</span>
          </template>
        </el-table-column>
        <el-table-column prop="sort" label="排序" align="center" width="150px">
          <template #default="scope">
            <el-input-number v-model="scope.row.newSort" :min="0" :max="9999" :precision="0" size="small" :controls="false" placeholder="数字1~9999" class="!w-120px" @blur="handleSortBlur(scope.row)" style="width: 120px" />
          </template>
        </el-table-column>
        <el-table-column label="剩余库存量" width="95">
          <template #default="scope">
            {{ data.obj2[scope.row["id"]] }}
          </template>
        </el-table-column>
        <el-table-column prop="redeemedQuantity" label="已兑换数量" width="95">
          <template #default="scope">
            {{ data.obj[scope.row["id"]] !== undefined ? data.obj[scope.row["id"]] : 0 }}
          </template>
        </el-table-column>
        <el-table-column label="分类">
          <template #default="scope">
            {{ scope.row["classification"] == "0" ? "积分换礼" : scope.row["classification"] == "1" ? "积分换券" : "" }}
          </template>
        </el-table-column>
        <el-table-column prop="creationTime" label="创建时间" :formatter="formatCreationTime" width="160"></el-table-column>
        <el-table-column fixed="right" label="操作" width="400">
          <template #default="scope">
            <el-button @click="UpdateClick(scope.row)" size="small" icon="el-icon-edit" type="primary">编辑</el-button>
            <el-popconfirm title="您确定要删除吗？" confirm-button-text="是" cancel-button-text="否" iconColor="red" @confirm="deleteClick(scope.row)">
              <template #reference>
                <el-button size="small" icon="el-icon-delete" type="danger">删除</el-button>
              </template>
            </el-popconfirm>
            <el-button @click="ViewMallImg(scope.row)" type="primary" size="small">详情</el-button>
            <el-button @click="ViewExchangeDetil(scope.row)" type="primary" size="small">查看兑换明细</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 3.分页 -->
      <div class="paginationBox">
        <Pagination v-model:page="option.page" v-model:size="option.pageSize" :total="data.total" @pagination="handleCurrentChange" />
      </div>
    </el-config-provider>
    <!-- 4.新增或更新操作 嵌套的表单  打开嵌套表单的 Dialog-->
    <el-dialog width="50%" title="商品信息" :show-close="true" @close="resetForm('ruleForm')" v-model="ruleForm.dialogFormVisible" top="5vh">
      <el-scrollbar max-height="75vh" style="padding-right: 20px;">
        <el-form :model="ruleForm" :rules="rules" ref="ruleFormRef" label-width="150px" class="demo-ruleForm">
          <el-row>
            <el-col :span="12">
              <el-form-item label="商品名称" prop="tradeName" required>
                <el-input v-model="ruleForm.tradeName" placeholder="请输入商品名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="兑换积分" prop="redeemPoints" required>
                <el-input v-model="ruleForm.redeemPoints" placeholder="请输入兑换积分"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="库存数量" prop="inventoryQuantity" required>
                <el-input v-model="ruleForm.inventoryQuantity" placeholder="请输入库存数量"></el-input>
              </el-form-item>
            </el-col>
  
            <el-col :span="12">
              <el-form-item label="虚拟兑换量" prop="virtuallyAmount" required>
                <el-input v-model="ruleForm.virtuallyAmount" placeholder="请输入虚拟兑换量"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- 可参与会员等级 -->
          <el-row>
            <el-col :span="12">
              <el-form-item label="会员等级" prop="participantType" required>
                <el-select v-model="ruleForm.participantType" clearable placeholder="请选择会员等级" @change="participantTypeChange" style="width: 100%">
                  <el-option v-for="item in data.participantTypeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12" v-if="ruleForm.participantType == '1'">
              <el-form-item prop="participants" label="部分会员等级" :required="ruleForm.participantType == '1'">
                <el-select v-model="ruleForm.participants" clearable multiple placeholder="请选择具体会员等级" style="width: 100%">
                  <el-option v-for="item in data.VipListData" :key="item.id" :label="item.vipName" :value="item.vipName" />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="分类" prop="classification" required>
                <el-select v-model="ruleForm.classification" clearable placeholder="请选择分类" @change="classificationChange" style="width: 100%">
                  <el-option v-for="item in data.ListData" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="couponType" label="券类型" required>
                <el-select v-model="ruleForm.couponType" clearable placeholder="请选择券类型" @change="couponTypeChange" style="width: 100%">
                  <el-option v-for="item in data.couponTypeList" :key="item.id" :label="item.name" :value="item.id" :disabled="ruleForm.classification != '' && ruleForm.classification == 0 && item.id == 2"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- 优惠券/虚拟商品 -->
          <el-row>
            <el-col :span="12" v-if="ruleForm.couponType == 1">
              <el-form-item prop="couponId" label="优惠券" :required="ruleForm.couponType == 1">
                <el-select v-model="ruleForm.couponId" clearable filterable placeholder="请选择优惠券" style="width: 100%">
                  <el-option v-for="item in data.couponListData" :key="item.coupon_id" :label="item.coupon_name" :value="item.coupon_id + ''"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12" v-if="ruleForm.couponType == 2">
              <el-form-item prop="virtuallyProductName" label="虚拟产品名称" :required="ruleForm.couponType == 2">
                <el-input v-model="ruleForm.virtuallyProductName" placeholder="请输入虚拟产品名称" maxlength="30"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12" v-if="ruleForm.couponType == 2">
              <el-form-item prop="virtuallyProductId" label="虚拟产品ID" :required="ruleForm.couponType == 2">
                <el-input v-model="ruleForm.virtuallyProductId" placeholder="请输入虚拟产品ID"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <el-form-item label="商品状态" prop="productStatus" required>
                <el-select v-model="ruleForm.productStatus" placeholder="请选择商品状态" style="width: 100%">
                  <el-option label="禁用" :value="'1'"></el-option>
                  <el-option label="启用" :value="'0'"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="每人最多兑换数量" prop="redeemQuantity" required>
                <el-input v-model="ruleForm.redeemQuantity" placeholder="请输入每人最多兑换数量"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="排序" prop="sort">
            <el-input-number v-model="ruleForm.sort" :min="0" :max="9999" :precision="0" placeholder="数字（从小到大）" class="!w-240px" />
          </el-form-item>
          <!--<el-row>
                <el-col :span="12" style="white-space: nowrap;">
                  <el-form-item label="自动启用时间" prop="enablingTime" >
                    <el-date-picker v-model="ruleForm.enablingTime" type="datetime" placeholder="请选择自动启用时间" :disabled-date="getDateTime"></el-date-picker>
                  </el-form-item>
                </el-col>
              </el-row>-->
          <el-row>
            <el-col :span="24">
              <el-form-item label="上传封面" prop="uploadCover">
                <el-upload 
                  action="" 
                  ref="myupload" 
                  :file-list="ruleForm.fileList" 
                  :on-preview="handlePreview"
                  :on-remove="handleRemove" 
                  :beforeUpload="beforeUpload" 
                  :http-request="handleUpload" 
                  :multiple="false"
                  :limit="1" 
                  accept=".png,.jpg,.jpeg">
                  <el-button size="small" type="primary">上传图片</el-button>
                </el-upload>
                <div style="color: red; padding-bottom: 20px"> * 图片格式 宽：800px；高：800px；大小不超过2MB；扩展名：".png，.jpg，.jpeg"</div>
                <div v-if="ruleForm.imageDiv && ruleForm.uploadCover">
                  <el-image :src="ruleForm.uploadCover" :fit="cover" style="width: 100px;height: 100px;" :preview-src-list="[ruleForm.uploadCover]"></el-image>
                  <el-button @click="imageClose" style="position: absolute;left: 85px;margin-top: -10px;" type="danger" icon="el-icon-close" circle size="mini"></el-button>
                  <div style="color: red;line-height: 16px;">点击可预览大图</div>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
  
          <el-row style="margin-bottom: 20px">
            <el-col :span="24">
              <el-form-item label="商品提示" prop="productTips">
                <el-input v-model="ruleForm.productTips" type="textarea" :rows="3" clearable placeholder="请输入商品提示" autocomplete="off" :maxlength="500"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row style="margin-bottom: 20px">
            <el-col :span="24">
              <el-form-item label="价值说明" prop="valueDescription">
                <el-input v-model="ruleForm.valueDescription" type="textarea" :rows="3" clearable placeholder="请输入价值说明" autocomplete="off" :maxlength="500"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="兑换须知" prop="redeemNotice">
            <div class="w-80%">
              <!-- <Editor v-model="ruleForm.redeemNotice" height="150px" /> -->
              <QuillEditor ref="quillEditorRef" v-model="ruleForm.redeemNotice"></QuillEditor>
            </div>
          </el-form-item>
          <el-form-item label="商品详情" prop="productDetails">
            <!--<el-input v-model="ruleForm.productDetails"/>-->
            <!--<QuillEditor ref="quillEditorRef" v-model="ruleForm.productDetails"></QuillEditor>-->
            <el-upload 
              action="" 
              ref="myupload" 
              :file-list="ruleForm.fileList1" 
              :on-preview="handlePreview1"
              :on-remove="handleRemove1" 
              :beforeUpload="beforeUpload1" 
              :http-request="handleUpload1" 
              :multiple="false"
              :limit="1" 
              accept=".png,.jpg,.jpeg">
              <el-button size="small" type="primary">上传图片</el-button>
            </el-upload>
            <div style="color: red; padding-bottom: 10px">* 图片大小不超过 2MB；扩展名：".png，.jpg，.jpeg"</div>
            <div v-if="ruleForm.imageDiv1 && ruleForm.productDetails">
              <el-image :src="ruleForm.productDetails" :fit="cover" style="width: 100px;height: 100px;" :preview-src-list="[ruleForm.productDetails]"></el-image>
              <el-button @click="imageClose1" style="position: absolute;left: 85px;margin-top: -10px;" type="danger" icon="el-icon-close" circle size="mini"></el-button>
              <div style="color: red;line-height: 16px;">点击可预览大图</div>
            </div>
          </el-form-item>
        </el-form>
      </el-scrollbar>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="resetForm('ruleForm')">取 消</el-button>
          <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
        </span>
      </template>
    </el-dialog>

    <el-dialog width="50%" title="积分说明配置" :show-close="true" @close="resetFormcoup('ruleFormcoup')" v-model="ruleFormcoup.dialogFormVisiblecoup">
      <el-form :model="ruleFormcoup" ref="ruleFormRefcoup" label-width="100px" class="demo-ruleForm">
        <el-row style="margin-bottom: 20px">
          <el-col>
            <!--<el-form-item label="积分说明配置" prop="pointsdescription" label-width="170px" >-->
            <el-input v-model="ruleFormcoup.pointsdescription" type="textarea" :rows="6" clearable placeholder="积分说明配置" autocomplete="off" :maxlength="500"></el-input>
            <!--</el-form-item>-->
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="resetFormcoup('ruleFormcoup')">取 消</el-button>
          <el-button type="primary" @click="submitFormcoup('ruleFormcoup')">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<!-- 本页的js -->
<script>
import zhCn from "element-plus/lib/locale/lang/zh-cn"
import { ref, reactive, onMounted, nextTick } from "vue";
import {
  ListPointsMall,
  updatePointsMall,
  CreatePointsMall,
  PointsMallById,
  DeltePointMall,
  GetAllListAsync,
  getCouponsRule,
  ExchangeLength,
  updateSort,
} from "../../http/pointsMall";
import { putToOSS, getFileNameUUID, getFileExtensions } from "@/http/oss";
import { getPointsCouponsDate, updatePointsCouponsDate, getvip } from "../../http/api";

import { FullTimeFormat } from "../../utils/common";
import { ElMessage } from "element-plus";
import { useRouter } from "vue-router";
import QuillEditor from "../../components/QuillEditor";
import Pagination from '@/components/pagination/index.vue'

export default {
  components: { QuillEditor, Pagination },
  methods: {
    formatCreationTime(row) {
      return FullTimeFormat(row.creationTime);
    },
  },
  name: "Category",
  setup() {
    const ruleFormRef = ref(null); //表单不能用this.$,取一个重名的
    // 跳转到分类设置页面
    const router = useRouter();
    const ruleFormRefcoup = ref(null); //表单不能用this.$,取一个重名的
    const quillEditorRef = ref(null);
    // 分类设置
    let ToSetting = () => {
      router.push({ name: "Pointsexchangetype" });
    };
    // 详情
    let ViewMallImg = (obj) => {
      router.push({ name: "PointsMallImg", query: { id: obj.id } });
    };
    // 查看兑换明细
    let ViewExchangeDetil = (obj) => {
      router.push({ name: "ExchangeDetails", query: { id: obj.id, couponType: obj.couponType } });
    };
    // 定义自动启用时间的范围
    let getDateTime = (time) => {
      return new Date(time).getTime() < Date.now() - 8.64e7;
    };
    // 声明数据table表格的内容
    let data = reactive({
      table: [],
      pages: 0,
      total: 0,
      total2: [],
      table2: [],
      ListData: [
        { id: "0", name: "积分换礼" },
        { id: "1", name: "积分换券" }
      ],
      participantTypeList: [
        { id: "0", name: "全部等级" },
        { id: "1", name: "部分等级" }
      ],
      VipListData: [],  // 客户等级列表
      couponListData: [],
      obj: {},
      obj2: {},
      couponTypeList: [
        { id: "1", name: "优惠券" },
        { id: "2", name: "虚拟产品" }
      ]
    });
    
    function getVipListDataFun(obj) {
      getvip(obj).then(res => {
        data.VipListData = res
      })
    }
    getVipListDataFun({})
    let tableLoading = ref(false)
    // 声明查询条件
    let option = reactive({
      tradeName: "",
      classification: "",
      page: 1,
      pageSize: 10
    });
    let indexMethod = (index) => {
      return index + (option.page - 1) * option.pageSize + 1;
    };
    let getCategoryListFun = async (obj) => {
      tableLoading.value = true
      let arr = await ListPointsMall(obj);
      tableLoading.value = false
      data.total = arr.totalCount;
      let items = arr.items;
      items.forEach((item) => {
        item.newSort = item.sort;
      });
      data.table = items;
      getExchangeLength(items);
    };
    let getExchangeLength = async (obj) => {
      let arr = await ExchangeLength({});
      data.total2 = arr;
      if (obj != null && obj.length > 0) {
        data.table.filter((item) => {
          data.total2.filter((itemtwo) => {
            if (item.id == itemtwo.pointsMallId) {
              data.obj[item.id] = itemtwo.pointsMallIdLenth;
              data.obj2[item.id] = item.inventoryQuantity - itemtwo.pointsMallIdLenth;
            }
          });
        });
        data.table.filter((item) => {
          if (!Object.keys(data.obj).includes(item.id)) {
            data.obj2[item.id] = item.inventoryQuantity;
          }
        });
      }
    };
    let UpdateClickcoup = async (obj) => {
      ruleFormcoup.dialogFormVisiblecoup = true; //显示出表单
      let PointsCouponsDate = await getPointsCouponsDate();
      ruleFormcoup.pointsdescription = PointsCouponsDate[0].pointsdescription;
      ruleFormcoup.id = PointsCouponsDate[0].id;
    };

    let getCouponsRuleList = async (obj) => {
      let arr = await getCouponsRule(obj);
      let couponRuleList = arr.data.coupon_rule_list;
      if (!ruleForm.classification || ruleForm.classification == "0") {
        data.couponListData = couponRuleList.filter((el) => el.coupon_type == 40);
      } else {
        data.couponListData = couponRuleList;
      }
    };
    // 初始化时，获取初始化数据
    getCategoryListFun({ page: 1, pageSize: 10 });
    getCouponsRuleList({});
    getExchangeLength();
    //点击页码获取数据
    let handleCurrentChange = () => {
      getCategoryListFun(option);
    };
    // 分类变化,清空优惠券数据
    let classificationChange = () => {
      data.couponListData = [];
      ruleForm.couponId = "";
      ruleForm.couponType = ""
      getCouponsRuleList({});
    };
    let participantTypeChange = (e) => {
      if(e != 1) {
        ruleForm.participants = []
      }
    }
    // 券类型改变 处理数据
    let couponTypeChange = () => {
      if(ruleForm.classification == '') {
        ruleForm.couponType = ''
        ElMessage.warning('请先选择分类')
        return
      } 
      ruleForm.virtuallyProductName = ""
      ruleForm.virtuallyProductId = ""
    }
    //查询方法,此处需要根据条件到后台进行筛选
    let getTabList = async () => {
      await getCategoryListFun(option);
    };

    //修改的表单参数
    let ruleForm = reactive({
      dialogFormVisible: false,
      id: "",
      tradeName: "",
      redeemPoints: "",
      productStatus: "0",
      residueQuantity: "",
      redeemedQuantity: "",
      classification: "",
      couponId: "",
      couponListData: [],
      addOrUpdate: 0, //0新增，1更新
      imageDiv: false,
      imageDiv1: false,
      uploadCover: "",
      productDetails: "",
      sort: 1, // 排序
      redeemNotice: "", // 兑换须知
      couponType: '', // 券类型 1 优惠券  2 虚拟券
      virtuallyProductName: '', // 虚拟产品名称
      virtuallyProductId: '', // 虚拟产品ID

      participantType: '', // 会员等级-0全部等级/1部分等级
      participants: [] // 部分会员等级
    });
    let ruleFormcoup = reactive({
      dialogFormVisiblecoup: false,
      id: ""
    });
    // 点击修改按钮,弹框，默认把原来的数据显示出来
    let UpdateClick = async (obj) => {
      ruleForm.dialogFormVisible = true; //显示出表单
      ruleForm.addOrUpdate = 1;
      ruleForm.id = obj.id;
      // 赋值默认值弹框获取ID号，方便下一步进行新增及修改等
      let Category = await PointsMallById(obj.id);
      ruleForm.tradeName = Category.tradeName;
      ruleForm.redeemPoints = Category.redeemPoints;
      ruleForm.inventoryQuantity = Category.inventoryQuantity;
      ruleForm.redeemQuantity = Category.redeemQuantity;
      ruleForm.classification = Category.classification;
      ruleForm.productStatus = Category.productStatus;
      ruleForm.virtuallyAmount = Category.virtuallyAmount;
      // ruleForm.enablingTime = Category.enablingTime;
      ruleForm.uploadCover = Category.uploadCover;
      ruleForm.imageDiv = true

      ruleForm.productDetails = Category.productDetails;
      ruleForm.imageDiv1 = true;

      ruleForm.productTips = Category.productTips;
      ruleForm.valueDescription = Category.valueDescription;
      
      ruleForm.couponType = Category.couponType // 券类型 1 优惠券  2 虚拟券
      ruleForm.virtuallyProductName = Category.virtuallyProductName // 虚拟产品名称
      ruleForm.virtuallyProductId = Category.virtuallyProductId // 虚拟产品ID

      
      ruleForm.participantType = Category.participantType, // 会员等级-0全部等级/1部分等级
      ruleForm.participants = Category.participants ? Category.participants.split(",") : [] // 部分会员等级-字符串拼接

      // if (Category.couponId) {
      //   ruleForm.couponId = Number(Category.couponId);
      // }
      ruleForm.couponId = Category.couponId;
      ruleForm.sort = Category.sort;
      ruleForm.redeemNotice = Category.redeemNotice ?? "";
      getCouponsRuleList({});
      nextTick(() => {
        quillEditorRef.value.$refs["quill-editor"].setHTML(
          ruleForm.redeemNotice
        );
      });
    };
    //点击新增按钮
    let AddClick = async () => {
      ruleForm.dialogFormVisible = true; //显示出表单
      ruleFormRef.value?.resetFields();
      ruleForm.addOrUpdate = 0;
      ruleForm.tradeName = "";
      ruleForm.redeemPoints = "";
      ruleForm.productStatus = "0";
      ruleForm.inventoryQuantity = "";
      ruleForm.redeemQuantity = 0;
      ruleForm.classification = "";
      ruleForm.virtuallyAmount = "";
      // ruleForm.enablingTime = "";
      ruleForm.uploadCover = "";
      ruleForm.fileList = []
      ruleForm.fileList1 = []
      ruleForm.productDetails = "";
      ruleForm.productTips = "";
      ruleForm.valueDescription = "";
      ruleForm.couponId = "";
      ruleForm.sort = 1;
      ruleForm.redeemNotice = "";
      ruleForm.dialogFormVisible = true; //显示出表单

      ruleForm.couponType = '' // 券类型 1 优惠券  2 虚拟券
      ruleForm.virtuallyProductName = '' // 虚拟产品名称
      ruleForm.virtuallyProductId = '' // 虚拟产品ID

      ruleForm.participantType = '', // 会员等级-0全部等级/1部分等级
      ruleForm.participants = [] // 部分会员等级
    };
    //表单验证
    let rules = reactive({
      tradeName: [{ required: true, message: "请输入商品名称" }],
      redeemPoints: [{ required: true, message: "请输入兑换积分" }],
      inventoryQuantity: [{ required: true, message: "请输入库存数量" }],
      redeemQuantity: [{ required: true, message: "请输入每人最多兑换量" }],
      productStatus: [{ required: true, message: "请选择商品状态" }],
      virtuallyAmount: [{ required: true, message: "请输入库存数量" }],
      classification: [{ required: true, message: "请选择商品分类" }],
      couponId: [{ required: true, message: "请选择优惠券" }],
      couponType: [{ required: true, message: "请选择券类型" }],
      virtuallyProductName: [{ required: true, message: "请输入虚拟产品名称" }],
      virtuallyProductId: [{ required: true, message: "请输入虚拟产品ID" }],
      participantType: [{ required: true, message: "请选择会员等级" }],
      participants: [{ required: true, message: "请选择具体可参与会员等级" }],
      sort: [
        { required: true, message: "请输入排序" },
        {
          validator: (rule, value, callback) => {
            if (value < 1 || value > 9999) {
              callback(new Error("排序必须在1-9999之间"));
            } else {
              callback();
            }
          },
        },
      ],
      redeemNotice: [
        { required: true, message: "请输入兑换须知" },
        { min: 10, message: "请输入至少10个字符" }
      ]
    });
    // 提交表单,新增或修改项目信息
    let submitForm = async (formName) => {
      let content = quillEditorRef.value.entity.content;
      ruleForm.redeemNotice = content;
      let con = false; 
      ruleFormRef.value.validate((valid) => {
        if (!valid) {
          con = true;
          return false;
        }
      });
      if (con) {
        return false;
      }
      try {
        if (ruleForm.addOrUpdate == 1) { // 更新
          let data = ruleForm;
          data.participants = data.participants.join(","), // 邀请人等级
          await updatePointsMall(data);
          ruleForm.uploadCover = "";
          ruleForm.productDetails = "";
          ruleForm.imageDiv = false;
          ruleForm.imageDiv1 = false;
          ruleForm.fileList = [];
          ruleForm.fileList1 = [];
          ElMessage.success({
            message: "修改成功！",
            duration: 2000,
            type: "success"
          });
        } else { // 新增
          let data = ruleForm;
          data.participants = data.participants.join(","), // 邀请人等级
          await CreatePointsMall(data);
          ruleForm.uploadCover = "";
          ruleForm.productDetails = "";
          ruleForm.imageDiv = false;
          ruleForm.imageDiv1 = false;
          ruleForm.fileList = [];
          ruleForm.fileList1 = [];
          ElMessage.success({
            message: "新增成功！",
            duration: 2000,
            type: "success"
          });
        }
        await getCategoryListFun(option);
        ruleForm.dialogFormVisible = false;
      } catch (error) {
        ElMessage.error({
          message: "操作失败，请重试！",
          duration: 2000
        });
      }
    };
    let submitFormcoup = async (formName) => {
      if (ruleFormcoup.pointsdescription == "" || ruleFormcoup.pointsdescription == null || ruleFormcoup.pointsdescription == undefined) {
        ElMessage.error({
          message: "积分说明配置不能为空",
          type: "error",
          offset: 130,
          duration: 1500
        });
        return false;
      }
      let data = {
        id: ruleFormcoup.id,
        pointsdescription: ruleFormcoup.pointsdescription
      };
      await updatePointsCouponsDate(data);
      ElMessage.success({
        message: "保存成功！",
        type: "success",
        offset: 130,
        duration: 2000
      });
      ruleFormcoup.dialogFormVisiblecoup = false;
    };
    let resetFormcoup = (formName) => {
      ruleFormcoup.dialogFormVisiblecoup = false;
    };
    //表单重置
    let resetForm = (formName) => {
      ruleFormRef.value.resetFields();
      ruleForm.couponId = "";
      nextTick(() => {
        quillEditorRef.value.$refs["quill-editor"].setHTML("");
      });
      ruleForm.dialogFormVisible = false;
    };
    let beforeUpload = (file) => {
      // 限制上传类型
      const fileExtensions = getFileExtensions(file.name) == ".jpg" || getFileExtensions(file.name) == ".png" || getFileExtensions(file.name) == ".jpeg";
      // 限制的上限为2M
      const max2M = file.size / 1024 / 1024 < 2;
      if (!fileExtensions) {
        ElMessage.error({
          message: "上传文件类型只能是 .jpg, .png, .jpeg 格式!",
          duration: 2000,
        });
      }
      if (!max2M) {
        ElMessage.error({
          message: "上传文件大小不能超过 2MB!",
          duration: 2000,
        });
      }
      return fileExtensions && max2M;
    };
    let beforeUpload1 = (file) => {
      // 限制上传类型
      const fileExtensions = getFileExtensions(file.name) == ".jpg" || getFileExtensions(file.name) == ".png" || getFileExtensions(file.name) == ".jpeg";
      // 限制的上限为2M
      const max2M = file.size / 1024 / 1024 < 2;
      if (!fileExtensions) {
        ElMessage.error({
          message: "上传文件类型只能是 .jpg, .png, .jpeg 格式!",
          duration: 2000,
        });
      }
      if (!max2M) {
        ElMessage.error({
          message: "上传文件大小不能超过 2MB!",
          duration: 2000,
        });
      }
      return fileExtensions && max2M;
    };
    let handleUpload = (option) => {
      let objName = "GoodsImg/" + getFileNameUUID() + getFileExtensions(option.file.name);
      putToOSS(`flieName/${objName}`, option.file).then((res) => {
        ruleForm.uploadCover = res.url;
        ruleForm.imageDiv = true;
      });
    };
    let handleUpload1 = (option) => {
      let objName = "GoodsImg/" + getFileNameUUID() + getFileExtensions(option.file.name);
      putToOSS(`flieName/${objName}`, option.file).then((res) => {
        ruleForm.productDetails = res.url;
        ruleForm.imageDiv1 = true;
      });
    };
    let handlePreview = (file) => {
      console.log(file);
    };
    let handlePreview1 = (file) => {
      console.log(file);
    };
    let handleRemove = (file, fileList) => {
      console.log(file, fileList);
    };
    let handleRemove1 = (file, fileList1) => {
      console.log(file, fileList1);
    };
    //删除上传的图片
    let imageClose = () => {
      ruleForm.uploadCover = "";
      ruleForm.imageDiv = false;
    };
    let imageClose1 = () => {
      ruleForm.productDetails = "";
      ruleForm.imageDiv1 = false;
    };
    let deleteClick = async (obj) => {
      let data = { id: obj.id };
      let delResult = await DeltePointMall(data);
      getCategoryListFun(option);
      if (delResult) {
        ElMessage.success({
          message: "删除成功！",
          duration: 2000,
          type: "success",
        });
      } else {
        ElMessage.error({
          message: "删除失败！",
          duration: 2000,
        });
      }
    };

    // 处理排序失去焦点
    const handleSortBlur = async (row) => {
      if (row.newSort == row.sort) {
        return;
      }
      try {
        let res = await updateSort({
          id: row.id,
          sort: row.newSort,
        });
        ElMessage.success("排序成功");
        // 同步旧值
        row.sort = row.newSort;
      } catch (e) {}
    };

    return {
      participantTypeChange,
      getVipListDataFun,
      tableLoading,
      data,
      handleCurrentChange,
      classificationChange,
      couponTypeChange,
      getTabList,
      option,
      UpdateClick,
      ruleForm,
      rules,
      submitForm,
      resetForm,
      ruleFormRef,
      UpdateClickcoup,
      ruleFormcoup,
      resetFormcoup,
      ruleFormRefcoup,
      submitFormcoup,
      AddClick,
      indexMethod,
      handlePreview,
      handleRemove,
      beforeUpload,
      handleUpload,
      imageClose,
      handlePreview1,
      beforeUpload1,
      handleRemove1,
      handleUpload1,
      imageClose1,
      deleteClick,
      ViewMallImg,
      ToSetting,
      ViewExchangeDetil,
      getDateTime,
      quillEditorRef,
      handleSortBlur,
      zhCn
    };
  },
};
</script>
<!-- 本页的css -->
<style lang="scss" scoped>
::v-deep {
  .paginationBox {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    .el-pagination.is-background .btn-prev, .el-pagination.is-background .btn-next {
      padding: 8px !important;
    }
  }
}
.select-box {
  display: flex;
  align-items: center;
  width: 120%;
  margin-bottom: 25px;

  >div {
    width: 15%;
    display: flex;
    align-items: center;

    span {
      width: 110px;
    }

    margin-right: 25px;
  }
}

.paginationBox {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}
</style>
