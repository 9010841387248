<template>
  <div
    class="CardInformation"
    v-loading="loading"
    element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
  >
    <el-scrollbar style="height: 100%" wrap-style="overflow-x: hidden;">
      <div class="no_data" v-if="!CardInformationArr.length">暂无数据</div>
      <div
        class="card_information_box"
        v-for="(item, index) in CardInformationArr"
        :key="index"
      >
        <div class="card_top">
          <div class="info_left textHide">
            {{ item.cardName }}
            <span class="status">（可用状态：{{ item.usableName }}）</span>
            <span style="color: #6ce1d5"
              >（卡类型：{{ item.cardTypeName }}）</span
            >
            <span class="status">（卡状态：{{ item.statusName }}）</span>
            <span>持卡ID：{{ item.mcardId }}</span>
            <span v-if="item.limitItemFlag" class="status">
              <span>{{
                item.isFixGroupCard ? "不限项目套餐" : "不限项目次卡"
              }}</span>
            </span>
          </div>
          <div class="info_right">
            <span>购卡日期:{{ item.openDate }}</span>
            <span class="ml15">有效期:{{ item.endDate }}</span>
          </div>
        </div>
        <div class="card_info">
          <el-row>
            <el-col :span="8">
              <span class="label">价格：</span>
              <span class="value">{{ item.price }}</span>
            </el-col>
            <el-col :span="8">
              <span class="label">应付价格：</span>
              <span class="value">{{ item.totalFee }}</span>
            </el-col>
            <el-col :span="8">
              <span class="label">免单金额：</span>
              <span class="value">{{ item.adjustFee }}</span>
            </el-col>
          </el-row>
          <el-row class="mt10">
            <el-col :span="8">
              <span class="label">剩余欠款：</span>
              <span class="value">{{ item.arrearsBalance }}</span>
            </el-col>
            <el-col :span="8">
              <span class="label">原总次数：</span>
              <span class="value">{{ item.totalCount }}</span>
            </el-col>
            <el-col :span="8">
              <span class="label">剩余总次数：</span>
              <span class="value">{{ item.lastCount }}</span>
            </el-col>
          </el-row>
          <el-row class="mt10">
            <el-col :span="8">
              <span class="label">优惠金额：</span>
              <span class="value">{{ item.couponAmount }}</span>
            </el-col>
            <el-col :span="8">
              <span class="label">实付金额：</span>
              <span class="value">{{ item.payment }}</span>
            </el-col>
          </el-row>
          <el-row class="mt10">
            <el-col :span="24">
              <el-row>
                <el-col :span="2"><span class="label">包含项目：</span></el-col>
                <el-col :span="20">
                  <div
                    v-if="item.limitItemFlag && item.isFixGroupCard"
                    class="value xm_box"
                  >
                    <p
                      v-for="(v, index) in item.details"
                      :key="index"
                      v-html="v"
                    ></p>
                  </div>
                  <div class="value xm_box" v-else-if="item.isFixGroupCard">
                    <div
                      v-for="(v, index) in item.unlimitedProjectPassesDtoList"
                      :key="index"
                    >
                      {{ v.itemName }}
                    </div>
                  </div>
                  <div class="value xm_box" v-else>
                    <el-row>
                      <div
                        v-for="(v, index) in item.classicCardDtoList"
                        :key="index"
                        style="padding: 0 5px"
                      >
                        {{ v.itemName }}（{{
                          "剩余" + v.lastCount + "次，"
                        }}单次耗卡价：¥{{ v.payPrice }}）
                      </div>
                    </el-row>
                  </div>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
          <el-row class="mt10">
            <el-col :span="19" class="textHide pr20">
              <span class="label">备注：</span>
              <span class="value">{{ item.remark }}</span>
            </el-col>
            <!-- <el-col :span="5">
              <span class="link" @click="lookLog(item)">查看记录</span>
            </el-col> -->
          </el-row>
        </div>
      </div>
      <div
        class="more_box"
        v-if="CardInformationArr.length < total"
        @click="lookMore"
      >
        查看更多
      </div>
      <div
        class="more_box color9"
        v-if="CardInformationArr && CardInformationArr.length > total"
      >
        没有更多了
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import { memberCardList } from "@/http/member";
import { ElMessage } from "element-plus";
export default {
  data() {
    return {
      CardInformationArr: [],
      searchVo: {
        memberId: this.$route.query.memberId,
      },
      loading: false,
      total: 0,
    };
  },
  created() {
    this.CardInformationArr = [];
    this.getList();
  },
  methods: {
    getList() {
      this.loading = true;
      memberCardList(this.searchVo)
        .then((res) => {
          this.loading = false;
          const { code, msg, data } = res;
          if (code != null && code != 0) {
            ElMessage.error(msg || "出错了");
            return;
          }
          this.CardInformationArr = data.rows;
          this.total = data.total;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    lookLog(item) {
      this.$emit("lookLog", item);
    },
    lookMore() {
      this.searchVo.current++;
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
.CardInformation {
  padding: 19px 0;
  height: 650px;
  .ml15 {
    margin-left: 15px;
  }
  .status {
    color: #4570ff;
    margin-left: 8px;
  }
  .mt10 {
    margin-top: 10px;
  }
  .pr20 {
    padding-right: 20px;
  }
  .ml24 {
    margin-left: 24px;
  }
  .no_data {
    color: #999;
    text-align: center;
    margin-top: 200px;
  }
  .more_box {
    text-align: center;
    color: #4570ff;
    cursor: pointer;
  }
  .color9 {
    color: #999 !important;
  }
  .card_information_box {
    height: 100%;
    margin-bottom: 21px;
    border: 1px solid #e9e9e9;
    .card_top {
      height: 40px;
      padding: 0 24px;
      background: #fafafa;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .info_left {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 22px;
      }
      .info_right {
        display: flex;
        justify-content: flex-end;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 22px;
      }
    }
    .card_info {
      padding: 10px 24px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      line-height: 22px;
      font-weight: 400;
      .xm_box {
        span:last-child {
          .danwei {
            display: none;
          }
        }
      }
      .label {
        color: rgba(0, 0, 0, 0.85);
      }
      .value {
        color: rgba(0, 0, 0, 0.65);
      }
      .link {
        color: #4570ff;
        cursor: pointer;
      }
    }
  }
}
</style>
