<template>
  <div>
    <el-form
      :model="queryParams"
      ref="queryFormRef"
      size="small"
      :inline="true"
      label-width="88px"
    >
      <el-form-item>
        <el-button @click="handleQuery" icon="el-icon-search" type="primary"
          >搜索</el-button
        >
        <el-button @click="resetQuery" icon="el-icon-refresh">重置</el-button>
        <el-button @click="openCreate" icon="el-icon-plus">新增</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="list"
      border
      stripe
      style="width: 100%"
      highlight-current-row
      v-loading="loading"
      row-class-name="row-class"
    >
      <el-table-column prop="id" label="ID" width="300" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="key" label="类型标签" min-width="200">
      </el-table-column>
      <el-table-column prop="value" label="类型键值" min-width="120">
      </el-table-column>
      <el-table-column label="操作" width="200">
        <template #default="scope">
          <el-button
            size="small"
            @click="openEdit(scope.row)"
            type="text"
            icon="el-icon-edit"
            >修改</el-button
          >
          <el-button
            size="small"
            @click="handleDelete(scope.row)"
            type="text"
            icon="el-icon-delete"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <ConsultationTypeForm ref="formRef" @success="getList" />
  </div>
</template>

<script>
import { onMounted, reactive, ref } from "vue";
import {
  getConsultationTypeList,
  deleteConsultationType,
} from "../../http/consultation";
import { FullTimeFormat } from "../../utils/common";
import ConsultationTypeForm from "./ConsultationTypeForm.vue";

import { ElMessage, ElMessageBox } from "element-plus";

export default {
  name: "ConsultationType", // 咨询类型管理
  components: { ConsultationTypeForm },
  setup() {
    const loading = ref(true);
    const list = ref([]);
    //声明数据
    let queryParams = reactive({});
    const queryFormRef = ref();

    let resetQuery = () => {
      queryFormRef.value.resetFields();
      handleQuery();
    };

    // 编辑
    const formRef = ref();
    let openEdit = async (row) => {
      formRef.value.open("update", row);
    };

    // 新增
    let openCreate = () => {
      formRef.value.open("create");
    };

    /** 删除按钮操作 */
    const handleDelete = async (row) => {
      ElMessageBox.confirm("是否删除所选中的数据？", "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          try {
            // 发起删除
            await deleteConsultationType(row);
            ElMessage.success("删除成功");
            // 刷新列表
            await getList();
          } catch {
            console.log("===");
          }
        })
        .catch(() => {});
    };

    // 获取列表
    const getList = async () => {
      loading.value = true;
      try {
        const data = await getConsultationTypeList(queryParams);
        list.value = data;
      } finally {
        loading.value = false;
      }
    };

    let handleQuery = () => {
      getList();
    };

    const formatTime = (date) => {
      return FullTimeFormat(date);
    };

    // 初始化
    onMounted(() => {
      getList();
    });

    return {
      loading,
      queryParams,
      queryFormRef,
      list,
      getList,
      handleQuery,
      resetQuery,
      formatTime,
      openEdit,
      openCreate,
      handleDelete,
      formRef,
    };
  },
};
</script>

<!-- 本页的css -->
<style lang="scss" scoped></style>
