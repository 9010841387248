import $http from "./index";
import { PLATFROM_CONFIG } from "../../public/config";
let base = PLATFROM_CONFIG.baseURL;
export const getBlurbList = () => {
  return $http.get(`/app/info/vipblurb/back/getvipblrub`, {});
};
export const getBlurbById = (data) => {
  return $http.get(`/app/info/vipblurb/back/detail`, {
    params: data,
  });
};
export const deletBlurbId = (data) => {
  return $http.get(`/app/info/vipblurb/back/delete`, {
    params: data,
  });
};
export const addVipBlurb = (data) => {
  return $http.post(`/app/info/vipblurb/back/new`, data);
};
export const updateVipBlurb = (data) => {
  return $http.post(`/app/info/vipblurb/back/update`, data);
};
